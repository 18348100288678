import React, { Component } from "react";
import { lsStore } from "./utils";
import TimeoutModal from "./modal/timeoutModal";
import './timer.scss';
import { PAUSE_TIMER_EVENT, RESUME_TIMER_EVENT } from "./constants";

export default class Timer extends Component {
  state = {
    minutes: this.props.timer.minutes,
    seconds: this.props.timer.seconds,
    isPaused: this.props.timer.isPaused,
  };

  componentDidUpdate(prevProps) {
    //   console.log(prevProps, this.props)
    if (
      this.props.timer.minutes !== prevProps.timer.minutes ||
      this.props.timer.hours !== prevProps.timer.hours ||
      this.props.timer.isPaused !== prevProps.timer.isPaused
    ) {
      this.setState(this.props.timer, () => {
        lsStore("timer", this.state);
        clearInterval(this.myInterval);
        this.startTimer();
      });

      if ((this.state.minutes == 0 && this.state.seconds == 0) && !this.props.isGameEnded) {
        this.props.endGame();
      }
    }
    if (prevProps.level != 5 && this.props.level == 5) {
      this.setState({
        minutes: 5,
        seconds: 0,
        isPaused: false
      })
    }
  }

  pauseTimer = () => {
    clearInterval(this.myInterval)
  }

  startTimer = () => {
    this.myInterval = setInterval(() => {
      if (this.state.isPaused) return;

      const { seconds, minutes } = this.state;
      if ((minutes == 0 && seconds == 0) && !this.props.isGameEnded) {
        this.props.endGame();
      }
      if (seconds > 0) {
        this.setState(
          ({ seconds }) => ({
            seconds: seconds - 1,
          }),
          () => {
            lsStore("timer", this.state);
          }
        );
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(this.myInterval);
          lsStore("timer", this.state);
        } else {
          this.setState(
            ({ minutes }) => ({
              minutes: minutes - 1,
              seconds: 59,
            }),
            () => {
              lsStore("timer", this.state);
            }
          );
        }
      }
    }, 1000);
  };
  componentDidMount() {
    this.startTimer();
    window.addEventListener(PAUSE_TIMER_EVENT, this.pauseTimer)
    window.addEventListener(RESUME_TIMER_EVENT, this.startTimer)
  }

  componentWillUnmount() {
    clearInterval(this.myInterval);
  }

  render() {
    const { minutes, seconds } = this.state;
    const remainingTime = (minutes * 60) + seconds;
    const totalTime = this.props.level == 5 ? 5 * 60 : 4 * 60;
    return (
      <div class='timer-bar'>
        {minutes === 0 && seconds === 0 ? (
          null
        ) : (
          this.state.isPaused === true && this.props.gameMode === 'free' ? ''
            :
            <div><p className="level">LEVEL {this.props.level}</p>
              <div className="timer">
                <div className="timer-fill" style={{ width: `${329 * ((((remainingTime) * 100) / totalTime) / 100)}px` }}></div>
                <span>{`${minutes < 10 ? `0${minutes}` : minutes}:${seconds < 10 ? `0${seconds}` : seconds}`}</span>
              </div></div>
        )}
      </div>
    );
  }
}


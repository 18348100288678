// import * as Sentry from "@sentry/react";
import { PAUSE_TIMER_EVENT, RESUME_TIMER_EVENT } from "../../constants";
import { NEW_DATA_EVENT } from "../../home";
import { storage } from "./storage";

const TYPE = "GAME";
const GAME_MOUNT = "GAME_MOUNT";
const GAME_DATA_SET = "GAME_DATA_SET";
const GAME_DATA_SAVE = "GAME_DATA_SAVE";
const GAME_END = "GAME_END";
const GAME_CREATE_MASTER_SESSION = "GAME_CREATE_MASTER_SESSION";
const GAME_BLURRED = "GAME_BLURRED";

/**
 * Get the parent app origin
 * @returns {string}
 */
const getParentOrigin = () => {
  const parentOrigin =
    window.location !== window.parent.location
      ? document.referrer
      : document.location.href;
  return parentOrigin.endsWith("/") ? parentOrigin.slice(0, -1) : parentOrigin;
};

/**
 * Event handler for catching postMessage events from parent app
 * @param {*} event
 * @returns
 */
const messageEventHandler = (event) => {
  // Ignore other message events which are not from assessment center origin
  if (event.origin !== getParentOrigin()) {
    return;
  }

  console.log("🚀 ~ Freeberry Event Handler ::", event);
  // Saving authToken & gameplayId from Assessment centre
  const { user, gameId, gameLevelData, gameDetails={}, status = "" } = event.data;

  if(status === PAUSE_TIMER_EVENT || status === RESUME_TIMER_EVENT) {
    window.dispatchEvent(new Event(status))
    return
  }

  if (gameLevelData?.extraData) {
    const parsedData = JSON.parse(
      JSON.parse(gameLevelData?.extraData)
    );
    Object.keys(parsedData).forEach(function (k) {
      sessionStorage.setItem(k, parsedData[k]);
    });
  }
  let gameOrgDetails = {
    mediaUrl: gameDetails?.mediaUrl,
    organizationName: gameDetails?.organizationName,
    gameName: gameDetails?.gameName
  };
  // Setting this way since some extensions override iframe and send their data keys in message
  // So only when data keys relevant to code arrive, populate data. 
  gameOrgDetails && storage.set.gameDetails(gameOrgDetails);
  gameLevelData && storage.set.levelData(gameLevelData ?? {});
  gameId && storage.set.gameId(gameId);

  // Todo: Add sentry
  // // Setting user context for sentry
  // try {
  //   Sentry.setUser(user);
  // } catch (error) {
  //   console.error(error);
  //   Sentry.captureException(error);
  // }

  // sending start game event
  window.dispatchEvent(new Event(NEW_DATA_EVENT));
  sendGameDataSetMessage();
};

/**
 * Send message to the parent app (Assessment centre)
 * @param {any} message message to be sent
 * @param {object} options optional params
 * @return
 */
const sendMessageToGamecenter = (message, options) => {
  const { addGameName } = options ?? {};

  const url = getParentOrigin();
  const data = addGameName
    ? { ...message, game: process.env.REACT_APP_GAME_NAME, type: TYPE }
    : { ...message, type: TYPE };

  window.parent.postMessage(data, url);
};

/**
 * Event for game mount
 */
 const sendGameLoadMessage = () => {
  const message = { status: GAME_MOUNT };
  sendMessageToGamecenter(message);
};

/**
 * Event to be sent when all game data has been set
 * and game can be played
 */
const sendGameDataSetMessage = () => {
  const message = { status: GAME_DATA_SET };
  sendMessageToGamecenter(message);
};

/**
 * Event for gameLevelData save, only data
 * is sent for this game.
 */
 const sendGameDataSaveMessage = (gameLevelData) => {
  const message = { status: GAME_DATA_SAVE, gameLevelData };
  sendMessageToGamecenter(message);
};

/**
 * Event for game end, send gameId to parent app
 */
const sendGameEndMessage = () => {
  const message = { status: GAME_END, gameId: storage.get.gameId() };
  sendMessageToGamecenter(message);
};

const createGameMasterSession = () => {
  const message = {
    status: GAME_CREATE_MASTER_SESSION
  };
  sendMessageToGamecenter(message);
};

const sendGameBlurredMessage = () => {
  const message = { status: GAME_BLURRED };
  sendMessageToGamecenter(message);
};

export const GC = {
  messageEventHandler,
  sendGameLoadMessage,
  sendGameDataSaveMessage,
  sendGameEndMessage,
  createGameMasterSession,
  sendGameBlurredMessage
};

import React, { Fragment } from "react";
import jwt_decode from "jwt-decode";
// import { PUZZLES } from "./PUZZLES";
import { BOX_WIDTH } from "./Base";
import { Piece, lsStore, loadRecords, storeRecords, lsLoad, lsClear } from "./utils";
import "./board.scss";
// import Modal from './modal';
import StarModal from "./modal/starModal";
import { motion } from "framer-motion";
import Cherries from "./static/cherries.svg";
import { storage } from "./services/config/storage";
import { GC } from "./services/config/gamecentreSevice";

const TOTAL_LEVELS = 5;
const STATS_KEY = 'levelStatsUnberry';
const optimalMoves = [3, 5, 7, 11, 0];

const getSeconds = (ob) => {
  if (!ob || !("minutes" in ob) || !("seconds" in ob)) {
    return 0;
  }
  return (ob.minutes * 60) + ob.seconds;
}
export class Move {
  constructor(props) {
    this.index = props.index;
    this.dx = props.dx;
    this.dy = props.dy;
  }
}

export class Board extends React.Component {
  constructor(props) {
    super(props);
    let pieces, size;
    let posStr = this.getPosStr();
    if (posStr) {
      // console.log({ posStr });
      let pieceBounds = {};
      let lines = posStr.replace(/ /g, "\n").split("\n");
      // console.log({ lines });
      size = lines.length;
      for (let y in lines) {
        y = y | 0;
        let line = lines[y];
        for (let x in line) {
          x = x | 0;
          let c = line[x];
          if (c === ".") continue;
          if (pieceBounds[c] === undefined) pieceBounds[c] = [x, y, x, y];
          else {
            let [ox, oy] = pieceBounds[c];
            // console.log(ox, oy);
            pieceBounds[c] = [ox, oy, x, y];
          }
        }
      }
      // console.log(pieceBounds);
      pieces = [];
      for (let piece in pieceBounds) {
        let [x1, y1, x2, y2] = pieceBounds[piece];
        pieces.push(
          new Piece({
            isMain: piece === "+",
            x: x1,
            y: y1,
            dx: x2 - x1 + 1,
            dy: y2 - y1 + 1,
          })
        );
      }
    } else {
      size = props.size;
      pieces = props.pieces;
    }
    // Create a [y][x] -> piece index mapping
    let board = [];
    for (let y = 0; y < size; y++) {
      let row = [];
      for (let x = 0; x < size; x++) row.push(-1);
      board.push(row);
    }
    for (let index in pieces) {
      let piece = pieces[index];
      this.place(board, piece, index | 0);
    }
    this.drag = this.drag.bind(this);
    this.dragEnd = this.dragEnd.bind(this);
    this.state = {
      // Board state stuff
      size: size,
      pieces: pieces,
      board: board,
      moves: [],
      futureMoves: [],
      // Dragging stuff
      draggingIndex: null,
      dragStartX: null,
      dragStartY: null,
      offsetX: null,
      offsetY: null,
      // For velocity calculations
      dragLastX: null,
      showLevelCompleteModal: false,
      showGameComplete: true,

      //Data Collection
      firstMoveForLevelDone: null,
      levelStats: lsLoad(STATS_KEY) ? lsLoad(STATS_KEY) : {},
      currentPuzzleStats: null,
      TOTAL_LEVELS: 5,
      showBonusPopup: false,
      apiInProgress: false,
      currentLevelStartTime: {
        minutes: 0,
        seconds: 0,
      }
    };
  }
  place(board, piece, index) {
    for (let x = piece.x; x < piece.x + piece.dx; x++)
      for (let y = piece.y; y < piece.y + piece.dy; y++) board[y][x] = index;
  }
  // Make a move. Sentinel value of null goes forward or backwards in history, depending on 'reverse'
  doMove(move, reverse) {
    let moves = this.state.moves;
    let futureMoves = this.state.futureMoves;
    if (move !== null) {
      moves.push(move);
      futureMoves = [];
    } else {
      if (reverse) {
        move = moves.pop();
        futureMoves.push(move);
      } else {
        move = futureMoves.pop();
        moves.push(move);
      }
    }
    let piece = this.state.pieces[move ? move.index : 0];
    // Remove old piece
    this.place(this.state.board, piece, -1);
    // Move piece position
    let dx = move.dx,
      dy = move.dy;
    if (reverse) {
      dx = -dx;
      dy = -dy;
    }
    piece.x += dx;
    piece.y += dy;
    // Place new piece
    this.place(this.state.board, piece, move.index);
    this.props.setMoves(moves);
    this.setState({
      board: this.state.board,
      pieces: this.state.pieces,
      moves: moves,
      futureMoves: futureMoves,
    });
    // Store the backward/forward move history into local storage, as well as
    // this puzzle's initial configuration so we can verify them if the page gets reloaded
    lsStore(
      "current-puzzle-moves",
      moves.map((m) => [m.index, m.dx, m.dy])
    );
    lsStore(
      "current-puzzle-future-moves",
      futureMoves.map((m) => [m.index, m.dx, m.dy])
    );
    lsStore("current-puzzle-pos-str", this.getPosStr());
  }
  isWon() {
    if (this.state.moves === 0) return false;
    for (let piece of this.state.pieces)
      if (piece.isMain) {
        const result = piece.x + piece.dx >= this.state.size;
        if (result) {
          setTimeout(() => {
            this.setState({ showLevelCompleteModal: true });
          }, 1000);
        }
        return result;
      }
    throw Error("What?");
  }
  getPosStr() {
    let posStr = this.props.posStr;
    if (this.props.puzzleNumber !== null)
      posStr = this.props.PUZZLES[this.props.puzzleNumber].board;
    return posStr;
  }
  updateRecords() {
    if (!this.isWon()) throw Error("bad update");
    let moves = this.state.moves.length;
    // Grab the initial position string, so the records aren't sensitive to the ordering of puzzles.
    let posStr = this.getPosStr();
    if (posStr) {
      let records = loadRecords();
      if (records[posStr] === undefined || records[posStr] > moves) {
        records[posStr] = moves;
        storeRecords(records);
      }
    }
  }
  getPieceBounds(piece) {
    let minX = 0,
      maxX = 0,
      minY = 0,
      maxY = 0;
    if (piece.dx > 1) {
      // Left
      for (
        let x = piece.x - 1;
        x >= 0 && this.state.board[piece.y][x] === -1;
        x--
      )
        minX--;
      // Right
      for (
        let x = piece.x + piece.dx;
        x < this.state.size && this.state.board[piece.y][x] === -1;
        x++
      )
        maxX++;
      // Check for moving the main piece out of bounds for the winning move
      //if (piece.isMain && x == this.state.size)
      //    maxX++;
    } else {
      // Up
      for (
        let y = piece.y - 1;
        y >= 0 && this.state.board[y][piece.x] === -1;
        y--
      )
        minY--;
      // Down
      for (
        let y = piece.y + piece.dy;
        y < this.state.size && this.state.board[y][piece.x] === -1;
        y++
      )
        maxY++;
    }
    return [
      minX * BOX_WIDTH,
      maxX * BOX_WIDTH,
      minY * BOX_WIDTH,
      maxY * BOX_WIDTH,
    ];
  }
  dragStart(index, e) {
    e.stopPropagation();
    e.preventDefault();
    if (e.targetTouches) e = e.targetTouches[0];
    if (e.button !== undefined && e.button !== 0) return;
    this.setState({
      dragStartX: e.pageX,
      dragStartY: e.pageY,
      draggingIndex: index,
    });
  }
  drag(e) {
    // Prevent default whether we're actually dragging a piece or not, to prevent
    // overscrolling. Pretty hacky!
    e.preventDefault();
    if (this.state.draggingIndex === null) return;
    e.stopPropagation();
    if (e.targetTouches) e = e.targetTouches[0];
    this.setState({
      offsetX: e.pageX - this.state.dragStartX,
      offsetY: e.pageY - this.state.dragStartY,
    });
  }
  dragEnd(e) {
    if (this.state.draggingIndex === null) return;
    // console.log(2);
    if (this.props.slideSound && this.props.soundEnabled) {
      if (!this.props.slideSound.paused) {
        this.props.slideSound.pause();
        this.props.slideSound.currentTime = 0;
      }
      this.props.slideSound.play();
    }
    e.stopPropagation();
    e.preventDefault();
    let piece = this.state.pieces[this.state.draggingIndex];
    let [minX, maxX, minY, maxY] = this.getPieceBounds(piece);
    let dx = 0,
      dy = 0;
    if (this.state.offsetX !== null)
      dx = Math.min(maxX, Math.max(minX, this.state.offsetX));
    if (this.state.offsetY !== null)
      dy = Math.min(maxY, Math.max(minY, this.state.offsetY));
    dx = Math.round(dx / BOX_WIDTH);
    dy = Math.round(dy / BOX_WIDTH);
    if (dx || dy) {
      this.doMove(
        new Move({ index: this.state.draggingIndex, dx: dx, dy: dy }),
        null
      );
      // const levelStatistics = lsLoad('levelStatsUnberry') ? lsLoad('levelStatsUnberry') : [];
      // const currentLevelStats = levelStatistics[this.props.puzzleNumber] || {};
      //currentLevelStats.firstMoveTime = lsLoad('timer');
      // firstMoveTime: null,
      //   moveTimes: [],
      //   optimalMoves: 0,
      //   totalMoves: 0,
      //   totalTime: null,
      //   isCompleted: false,
      //   numUndo: 0,
      //   numRestart: 0,
      this.setState({
        currentPuzzleStats: {
          ...this.state.currentPuzzleStats,
          firstMoveTime: !this.state.currentPuzzleStats.firstMoveTime ? lsLoad('timer') : this.state.currentPuzzleStats.firstMoveTime,
          moveTimes: [...this.state.currentPuzzleStats.moveTimes, lsLoad('timer')],
          totalMoves: this.state.currentPuzzleStats.totalMoves + 1,
          totalTime: lsLoad('timer'),
          isCompleted: false,
        }
      });
      //levelStatistics[this.props.puzzleNumber] = currentLevelStats;
      //lsStore('levelStatsUnberry', levelStatistics);

      // Check for winning states and update local storage to reflect the possible
      // new record. We do this in dragEnd since it's the only place where a "real"
      // move is made (i.e. not retracing history).
      if (this.isWon()) this.updateRecords();
    }
    this.setState({ offsetX: null, offsetY: null, draggingIndex: null });
  }
  resetLevelStats = () => {
    this.props.setMoves(0);
    // this.props.resetTimer();
  };

  resetLevel = () => {
    this.setState({
      currentPuzzleStats: {
        ...this.state.currentPuzzleStats,
        resetCount: this.state.currentPuzzleStats.resetCount + 1,
        resetMovesStamp: this.state.currentPuzzleStats?.moveTimes?.length || 0,
      }
    }, () => {
      this.resetLevelStats();
      this.props.selectPuzzle(this.props.puzzleNumber);
    })

  };
  componentWillMount() {
    // Make the mouse move/mouse up event handlers global, since we want to know whenever these
    // things happen, no matter where the mouse is
    document.addEventListener("mousemove", this.drag, { passive: false });
    document.addEventListener("mouseup", this.dragEnd, { passive: false });
    document.addEventListener("touchmove", this.drag, { passive: false });
    document.addEventListener("touchend", this.dragEnd, { passive: false });
    // Try to grab move lists for this puzzle, making sure they're for this puzzle
    let lsPosStr = lsLoad("current-puzzle-pos-str");
    if (lsPosStr === this.getPosStr()) {
      // We have to set these move lists up in a particular order, since doMove overwrites the
      // values in local storage. So first, we load both the backward and forward lists.
      // Then, make all the moves in the backwards list, and finally, load the future moves.
      let moves = lsLoad("current-puzzle-moves") || [];
      let futureMoves = lsLoad("current-puzzle-future-moves") || [];
      for (let [index, dx, dy] of moves)
        this.doMove(new Move({ index: index, dx: dx, dy: dy }));
      this.setState({
        futureMoves: futureMoves.map(
          ([index, dx, dy]) => new Move({ index: index, dx: dx, dy: dy })
        ),
      });
    }
  }
  componentWillUnmount() {
    document.removeEventListener("mousemove", this.drag);
    document.removeEventListener("mouseup", this.dragEnd);
    document.removeEventListener("touchmove", this.drag);
    document.removeEventListener("touchend", this.dragEnd);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.isGameEnded && !prevProps.isGameEnded) {
      (async () => {
        if (this.state.apiInProgress) {
          return;
        }
        if (this.state.apiInProgress) {
          return;
        }
        const stats = lsLoad(
          STATS_KEY
        );
        const currentTimeNow = lsLoad('timer');
        const stat = stats.gameData.levelData[this.props.puzzleNumber];
        const moveTimesSeconds = stat.moveTimes.map(mt => (mt.minutes * 60) + mt.seconds);
        const lastLevelStartTime = {
          minutes: 5,
          seconds: 0,
        };
        /** Use only when gameIsEnded and user does not make a move **/
        const noMovesTotalTime = (stat.levelStartTime.minutes * 60) + stat.levelStartTime.seconds;
        const avgMoveTime = moveTimesSeconds.map((mt, index) => index == 0 ? getSeconds(this.props.puzzleNumber == 4 ? lastLevelStartTime : stat.levelStartTime) - mt : moveTimesSeconds[index - 1] - mt).reduce((ac, cv) => ac + cv, 0) / stat.moveTimes.length;
        const timeToPlay = this.props.puzzleNumber == 4 ? 300 : 240;
        const totalLevelTime = this.props.puzzleNumber + 1 == 5 && !this.props.endClickTime ? 300 :
          stat.totalMoves === 0 ? noMovesTotalTime : 
          (timeToPlay - (!isNaN(stat.totalTime) && stat.totalTime > 0 ? stat.totalTime : (stat.totalTime.minutes * 60) + stat.totalTime.seconds));
        const payloadToSend = {
          //"game": "freeberry",
          "gameData": {
            "levelData": [
              {
                level: this.props.puzzleNumber + 1,
                totalTime: totalLevelTime,
                totalMoves: Array.isArray(stat.moveTimes) ? stat.moveTimes.length : stat.totalMoves,
                optimalMoves: stat.optimalMoves,
                isLevelCleared: this.props.puzzleNumber == 4 ? false : true,
                averageMoveTime: avgMoveTime ? avgMoveTime.toFixed(2) : 0,
                resetCount: stat.numRestart ? stat.numRestart : stat.resetCount,
                undoCount: stat.numUndo ? stat.numUndo : stat.undoCount,
                optimalMoves: optimalMoves[this.props.puzzleNumber],
                movesAfterReset: stat.moveTimes.length - stat.resetMovesStamp,
                timeForFirstMove: Math.abs(getSeconds(this.props.puzzleNumber == 4 ? lastLevelStartTime : stat.levelStartTime) - getSeconds(stat.firstMoveTime)),
                numTimesClose: this.props.numClose,
                firstClickOnEndTime: this.props.firstClickCloseTime,
                timeAtGameEnd: this.props.endClickTime,
                lastLevelEmotion: this.props.lastLevelEmotion
              }
            ],

            "totalLevelCleared": stats.gameData.levelData.filter(ld => ld.levelStartTime).length,
            "totalTimeTaken": (currentTimeNow.minutes * 60) + currentTimeNow.seconds,
            "extraData": JSON.stringify(JSON.stringify(sessionStorage)),
          }
        };
        if (this.props.lastLevelEmotion) {
          payloadToSend.gameData.levelData[0].lastLevelEmotion = this.props.lastLevelEmotion;
        }

        //let sessionId = jwt_decode(sessionData.authToken).sessionId;

        // const gameId = storage.get.gameId();
        // const authToken = storage.get.authToken();
        // const gameplayId = storage.get.gameplayId();

        if (!this.state.apiInProgress){
          this.setState({ apiInProgress: true });
          GC.sendGameDataSaveMessage(payloadToSend);
          this.setState({ apiInProgress: false });
        }

        // if (!this.state.apiInProgress && gameId) {
        //   this.setState({ apiInProgress: true });
        //   try {
        //     await fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/session/v1/${gameId}/link/${gameplayId}`, {
        //       method: 'POST', // *GET, POST, PUT, DELETE, etc.
        //       mode: 'cors', // no-cors, *cors, same-origin
        //       cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        //       credentials: 'same-origin', // include, *same-origin, omit
        //       headers: {
        //         'Content-Type': 'application/json',
        //         'Authorization': authToken
        //         // 'Content-Type': 'application/x-www-form-urlencoded',
        //       },
        //       redirect: 'follow', // manual, *follow, error
        //       referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        //       body: JSON.stringify(payloadToSend) // body data type must match "Content-Type" header
        //     });
        //   } catch(err) {
        //     console.log(`ERROR::${process.env.REACT_APP_GAME_NAME}::syncGameSessionDataFunction::${err}`);
        //   } finally {
        //     this.setState({ apiInProgress: false });
        //   }

        // }
      })();
      return;
    }
    if (!this.state.currentPuzzleStats) {
      //debugger;
      this.setState({
        currentPuzzleStats: {
          ...this.state.levelStats.gameData.levelData[this.props.puzzleNumber] ? this.state.levelStats.gameData.levelData[this.props.puzzleNumber] : {},
          levelStartTime: this.state.levelStats.gameData.levelData[this.props.puzzleNumber].levelStartTime ? this.state.levelStats.gameData.levelData[this.props.puzzleNumber].levelStartTime : (lsLoad('timer') ? lsLoad('timer') : { minutes: 4, seconds: 0 }),
        },
      })
    }

    const levelInfo = lsLoad(STATS_KEY) || this.state.levelStats;
    if (this.state.currentPuzzleStats) {
      levelInfo.gameData.levelData[this.props.puzzleNumber] = this.state.currentPuzzleStats;
    }
    lsStore(STATS_KEY, levelInfo);
  }
  getResult = () => {
    const moves = this.props.moves.length;
    const totalMoves = this.props.PUZZLES[this.props.puzzleNumber].moves;
    if (moves === totalMoves) {
      return 3;
    } else if (moves <= totalMoves * 2) {
      return 2;
    } else {
      return 1;
    }
  };
  render() {
    const { PUZZLES } = this.props;

    let record = null;
    let posStr = this.getPosStr();
    if (posStr) {
      let records = loadRecords();
      if (records[posStr] !== undefined) record = records[posStr];
    }
    let optimal = null;
    if (this.props.puzzleNumber !== null)
      optimal = PUZZLES[this.props.puzzleNumber].moves;
    let isWon = this.isWon();



    return (
      <div className="main__container">
        <div className="left__content">
          <span className="level-detail">
            Level {this.props.puzzleNumber + 1}
          </span>
          <div className="left__content-controls">
            <motion.button
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              onClick={(e) => this.resetLevel()}
              className="control-btn"
            >
              <img src={require("./static/restartBtn.svg")} alt="restart" />
            </motion.button>
            <motion.button
              animate={
                this.state.moves.length === 0
                  ? { opacity: 0.2 }
                  : { opacity: 1 }
              }
              whileHover={this.state.moves.length !== 0 ? { scale: 1.1 } : {}}
              whileTap={{ scale: 0.9 }}

              className="control-btn"

              onClick={(e) => {

                if (this.state.moves.length === 0) return;
                this.doMove(null, true);
                this.setState({
                  currentPuzzleStats: {
                    ...this.state.currentPuzzleStats,
                    undoCount: this.state.currentPuzzleStats.undoCount + 1,
                  }
                })
              }}
            >
              <img src={require("./static/left.svg")} alt="music" />
            </motion.button>
          </div>
        </div>

        <div className={"main__content"}>
          <StarModal
            optimalMoves={optimalMoves[this.props.puzzleNumber]}
            pauseGame={this.props.pauseGame}
            isOpen={(isWon && this.state.showLevelCompleteModal) || this.props.isGameEnded}
            img={Cherries}
            puzzleNumber={this.props.puzzleNumber}
            levelResult={this.getResult()}
            moves={this.props.moves.length}
            gameComplete={this.props.isGameEnded || this.props.puzzleNumber == (this.state.TOTAL_LEVELS - 1)}
            totalMoves={PUZZLES[this.props.puzzleNumber].moves}
            levelCompleteSound={this.props.levelCompleteSound}
            showBonusPopup={this.state.showBonusPopup}
            onNextLevel={async (isBonus) => {
              let currentPuzzleNumber = this.props.puzzleNumber;
              if ((currentPuzzleNumber + 1) >= TOTAL_LEVELS || this.props.isGameEnded) {
                lsClear();
                console.log("Finishing Game...")
                setTimeout(() => {
                  GC.sendGameEndMessage();
                }, 2000);
                return;
              }
              if (this.props.isGameEnded && !this.state.showBonusPopup) {
                this.setState({
                  showBonusPopup: true,
                });
                return;
              }
              if (this.props.isGameEnded && this.state.showBonusPopup) {
                this.setState({
                  showBonusPopup: false,
                });
                this.setState({
                  currentPuzzleStats: null,
                }, async () => {
                  this.resetLevelStats();
                  this.props.selectPuzzle((this.props.isGameEnded) ? 4 : currentPuzzleNumber + 1);
                });
                return;
              }

              if ((currentPuzzleNumber) <= 3) {
                lsStore('level4time', lsLoad('timer'));
              }
              console.log();
              const stats = lsLoad(
                STATS_KEY
              );

              if (((currentPuzzleNumber + 1) == 4) && !this.state.showBonusPopup) {
                this.setState({
                  showBonusPopup: true,
                });
                return;
              }
              else if (((currentPuzzleNumber + 1) == 4) && this.state.showBonusPopup) {
                this.setState({
                  showBonusPopup: false,
                });

              }
              if ((currentPuzzleNumber + 1) >= TOTAL_LEVELS) {
                lsClear();
                console.log("Finishing Game...")
                setTimeout(() => {
                  GC.sendGameEndMessage();
                }, 2000);
                return;
              }

              this.setState({
                currentPuzzleStats: null,
              }, async () => {
                this.resetLevelStats();

                this.props.selectPuzzle((this.props.isGameEnded) ? 3 : currentPuzzleNumber + 1);
                const currentTimeNow = lsLoad('timer');
                const stat = stats.gameData.levelData[currentPuzzleNumber];
                const timeToPlay = currentPuzzleNumber == 4 ? 300 : 240;

                const moveTimesSeconds = stat.moveTimes.map(mt => (mt.minutes * 60) + mt.seconds);
                const avgMoveTime = moveTimesSeconds.map((mt, index) => index == 0 ? getSeconds(stat.levelStartTime) - mt : moveTimesSeconds[index - 1] - mt).reduce((ac, cv) => ac + cv, 0) / stat.moveTimes.length;
                /** Track user's current time on a level **/
                const levelStayTime = ((stat.levelStartTime.minutes * 60) + stat.levelStartTime.seconds) - ((stat.totalTime.minutes * 60) + stat.totalTime.seconds)
                const payloadToSend = {
                  //"game": "freeberry",
                  "gameData": {
                    "levelData": [
                      {
                        level: currentPuzzleNumber + 1,
                        totalTime: levelStayTime,
                        totalMoves: Array.isArray(stat.moveTimes) ? stat.moveTimes.length : stat.totalMoves,
                        optimalMoves: stat.optimalMoves,
                        isLevelCleared: currentPuzzleNumber == 4 ? false : true,
                        averageMoveTime: avgMoveTime ? avgMoveTime.toFixed(2) : 0,
                        resetCount: stat.numRestart ? stat.numRestart : stat.resetCount,
                        undoCount: stat.numUndo ? stat.numUndo : stat.undoCount,
                        optimalMoves: optimalMoves[currentPuzzleNumber],
                        movesAfterReset: stat.moveTimes.length - stat.resetMovesStamp,
                        timeForFirstMove: Math.abs(getSeconds(stat.levelStartTime) - getSeconds(stat.moveTimes?.[0])),
                        numTimesClose: this.props.numClose || 0,
                        firstClickOnEndTime: this.props.firstClickCloseTime || 0,
                        timeAtGameEnd: this.props.endClickTime || 0,
                        lastLevelEmotion: this.props.lastLevelEmotion || 0,
                      }
                    ],
                    "totalLevelCleared": currentPuzzleNumber + 1,
                    "totalTimeTaken": (currentTimeNow.minutes * 60) + currentTimeNow.seconds,
                    "extraData": JSON.stringify(JSON.stringify(sessionStorage)),
                  }
                };
                if (this.props.lastLevelEmotion) {
                  payloadToSend.gameData.levelData[0].lastLevelEmotion = this.props.lastLevelEmotion;
                }

                //let sessionId = jwt_decode(sessionData.authToken).sessionId;
                // console.log("KILL")
                if (!this.state.apiInProgress){
                  this.setState({ apiInProgress: true });
                  GC.sendGameDataSaveMessage(payloadToSend);
                  this.setState({ apiInProgress: false });
                }
                
                // const gameId = storage.get.gameId();
                // const authToken = storage.get.authToken();
                // const gameplayId = storage.get.gameplayId();

                // if (!this.state.apiInProgress && gameId) {
                //   this.setState({ apiInProgress: true });
                //   try {
                //     await fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/session/v1/${gameId}/link/${gameplayId}`, {
                //       method: 'POST', // *GET, POST, PUT, DELETE, etc.
                //       mode: 'cors', // no-cors, *cors, same-origin
                //       cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                //       credentials: 'same-origin', // include, *same-origin, omit
                //       headers: {
                //         'Content-Type': 'application/json',
                //         'Authorization': authToken
                //         // 'Content-Type': 'application/x-www-form-urlencoded',
                //       },
                //       redirect: 'follow', // manual, *follow, error
                //       referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                //       body: JSON.stringify(payloadToSend) // body data type must match "Content-Type" header
                //     });
                //     this.setState({
                //       TOTAL_LEVELS: 5,
                //     })
                //   } catch(err) {
                //     console.log(`ERROR::${process.env.REACT_APP_GAME_NAME}::syncGameSessionDataFunction::${err}`);
                //   } finally {
                //     this.setState({ apiInProgress: false });
                //   }

                // }

              });

            }}
          >

          </StarModal>

          <motion.div
            className="board-content"
            initial={{ scale: 0.8 }}
            animate={{ scale: 1 }}
            transition={{
              type: "spring",
              stiffness: 260,
              damping: 20,
            }}
          >
            <div
              className="board-component"
              style={{
                height: this.state.size * BOX_WIDTH,
                width: this.state.size * BOX_WIDTH,
              }}
            >
              {this.state.pieces.map((piece, index) => {
                let x = piece.x * BOX_WIDTH,
                  y = piece.y * BOX_WIDTH;
                let isWinningPiece = piece.isMain && isWon;
                if (index === this.state.draggingIndex) {
                  let [minX, maxX, minY, maxY] = this.getPieceBounds(piece);
                  if (this.state.offsetX !== null)
                    x += Math.min(maxX, Math.max(minX, this.state.offsetX));
                  if (this.state.offsetY !== null)
                    y += Math.min(maxY, Math.max(minY, this.state.offsetY));
                }
                return (
                  <Fragment key={index}>
                    <div
                      key={index}
                      onMouseDown={(e) => this.dragStart(index, e)}
                      onTouchStart={(e) => this.dragStart(index, e)}
                      style={{
                        marginTop: y,
                        marginLeft: isWinningPiece ? x + 2 * BOX_WIDTH : x,
                        height: piece.dy * BOX_WIDTH,
                        width: piece.dx * BOX_WIDTH,
                        // Winning piece spins around and gets big enough to cover the screen
                        // transform: isWinningPiece
                        //   ? "opacity ease 250ms"
                        //   : "inherit",
                        animate: isWinningPiece
                          ? "backOutRight 0.35s linear"
                          : "",
                        opacity: isWinningPiece ? "0" : "inherit",
                        zIndex: isWinningPiece ? "15" : "inherit",
                        // Animate movements, but only pieces that aren't currently being dragged
                        transition:
                          index !== this.state.draggingIndex
                            ? isWinningPiece
                              ? "transform 750ms 250ms, opacity 750ms, margin ease-in 250ms"
                              : "margin 250ms"
                            : "",
                        position: "absolute",
                      }}
                    >
                      <div
                        className={
                          "block" + (piece.isMain ? " main-block" : "")
                        }
                      >
                        {/* <span>{index+1}</span> */}
                      </div>
                      <div className="block block-shadow"></div>
                    </div>
                  </Fragment>
                );
              })}
            </div>
            {/* game controls */}
          </motion.div>

          <div style={{ paddingTop: "10px" }} />
          <div>
            <input
              type="button"
              disabled={this.props.puzzleNumber === 0}
              onClick={(e) =>
                this.props.selectPuzzle(this.props.puzzleNumber - 1)
              }
              className="button smallButton"
              value="&#x25C0;&#xFE0E;"
            />
            <div style={{ display: "inline", width: "80px", margin: "5px" }}>
              Level {this.props.puzzleNumber + 1}
            </div>
            <input
              type="button"
              disabled={this.props.puzzleNumber === PUZZLES.length - 1}
              onClick={(e) =>
                this.props.selectPuzzle(this.props.puzzleNumber + 1)
              }
              className="button smallButton"
              value="&#x25B6;&#xFE0E;"
            />
            <span style={{ paddingLeft: "10px" }} />
            <input
              type="button"
              onClick={(e) => this.props.selectPuzzle(null)}
              className="button smallButton"
              value="List"
            />
          </div>
        </div>

        <div className="right__content">
          <span className="level-detail-right">
            Level {this.props.puzzleNumber + 1}
          </span>
        </div>
      </div>
    );
  }
}

import React, { createContext } from "react";
// import { PUZZLES } from "./PUZZLES";
import { loadRecords, lsLoad } from "./utils";
import "./puzzlelist.scss";
import { motion } from "framer-motion";
import cx from 'classnames'

export const PuzzleList = (props) => {
  const { gameMode, setGameMode, PUZZLES} =  props;
  let records = loadRecords();
  let starsList = lsLoad("berriesCollection");
  // const [mode, setGameMode] = useState("regular"); // or custom


  return (
    <div className="puzzle__list">
      <div className="puzzle__list-header">Select a puzzle!</div>
      <div className="puzzle__list-tab">
        <motion.button
          animate={gameMode === "free" ? { opacity: 0.6 } : { opacity: 1 }}
          whileHover={gameMode === "free" ? { scale: 1.1 } : {}}
          whileTap={{ scale: 0.9 }}
          className="puzzle__list-tab-item btn"
          onClick={() => {
            setGameMode("regular");
          }}
        >
          Regular
        </motion.button>
        <motion.button
          animate={gameMode === "regular" ? { opacity: 0.6 } : { opacity: 1 }}
          whileHover={gameMode === "regular" ? { scale: 1.1 } : {}}
          whileTap={{ scale: 0.9 }}
          className="puzzle__list-tab-item btn"
          onClick={() => {
            setGameMode("free");
          }}
        >
          Free Mode
        </motion.button>
      </div>
      <div className="puzzle__list-container">
        {PUZZLES.map((p, i) => (
          <div
            className={cx("puzzle__list-item btn", ((records && records[p.board] !== undefined) || gameMode === 'free') ? '' : 'disabled-item')}
            key={i}
            onClick={() => {
                ((records && records[p.board] !== undefined) || gameMode === 'free') &&
                props.selectPuzzle(i)
            }}
          >
            <div className="puzzle__list-number">
              <strong>Puzzle {i + 1}</strong>
            </div>
            <div className="puzzle__list-records">Moves : {p.moves} </div>
            {records && records[p.board] !== undefined ? (
              <>
                <div className="puzzle__list-records">
                  Record: {records[p.board]}
                </div>
                <div className="puzzle__list-records">
                  {starsList.map(() => (
                    <img
                      height="32"
                      width="32"
                      src={require("./static/star.png")}
                      alt="star"
                    />
                  ))}
                </div>
              </>
            ) : (
              ""
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

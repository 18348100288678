import React, { useEffect } from 'react';
import { Modal as ReactModal } from "react-responsive-modal";
import './modal.scss';
import usePrevious from "../usePrevious";
import { storeBerries, lsLoad } from "../utils";
import MaskModal from "../static/maskModal.svg";
import { Modal as ScoreModal } from 'react-modal';
import GameEnd from '../icons/game-end.svg';

const STATS_KEY = 'levelStatsUnberry';

export default function StarModal(props) {
  const { levelResult, moves, totalMoves, puzzleNumber } = props;
  let images = ''
  if (levelResult === 3) {
    images = new Array(3).fill(1).map((_, index) => {
      return <img key={index} className="modal-img-stars smilingBerry" src={require("../static/berrySmiling.svg")} alt="img-modal" />;
    });
  } else if (levelResult === 2) {
    images = new Array(3).fill(1).map((_, index) => {
      return index !== 2 ?
        <img key={index} className="modal-img-stars smilingBerry" src={require("../static/berrySmiling.svg")} alt="img-modal" /> :
        <img key={index} className="modal-img-stars smilingBerry" src={require("../static/emptyBerry.svg")} alt="img-modal" />;
    });
  } else if (levelResult === 1) {
    images = new Array(3).fill(1).map((_, index) => {
      return index === 0 ?
        <img key={index} className="modal-img-stars smilingBerry" src={require("../static/sadBerry.svg")} alt="img-modal" /> :
        <img key={index} className="modal-img-stars smilingBerry" src={require("../static/emptyBerry.svg")} alt="img-modal" />;
    });
  }

  const { isOpen } = props
  const prevIsOpen = usePrevious(isOpen);
  useEffect(() => {
    if (!prevIsOpen && isOpen) {
      // console.log({puzzleNumber});
      props.levelCompleteSound.play();
      const berriesCollection = lsLoad("berriesCollection");
      if (berriesCollection) {
        berriesCollection[puzzleNumber] = levelResult;
        storeBerries(berriesCollection);
      } else {
        const arr = [];
        arr[puzzleNumber] = levelResult;
        storeBerries(arr);
      }
    }
  })
  useEffect(() => {
    props.pauseGame(isOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  const totalTimesForGames = 4 * 60;
  let totalGameMoves = null;
  let timeTaken = null;

  if (props.gameComplete) {
    
    totalGameMoves = lsLoad(STATS_KEY)?.gameData?.levelData?.reduce((ac, cv) => ac + cv.moveTimes.length, 0);
    timeTaken = (lsLoad('level4time')?.minutes * 60) + lsLoad('level4time')?.seconds;
    timeTaken = props.puzzleNumber < 4 ? timeTaken: totalTimesForGames - timeTaken;
    if (props.puzzleNumber == 4) {
      timeTaken = timeTaken + ((300) - ((lsLoad('timer')?.minutes * 60) + lsLoad('timer')?.seconds));
    }
    if (!timeTaken) timeTaken = totalTimesForGames;
  }





  return (
    <ReactModal
      classNames={{
        overlay: "modal-component-overlay",
        modal: "modal-component-container",
      }}
      center
      open={props.isOpen}
      onClose={() => { }}
      showCloseIcon={false}
      closeOnOverlayClick={false}
    >
      <div className="modal-content">
        {!props.showBonusPopup ? (
          <React.Fragment>
            {props.gameComplete ? null : <p className="puzzle-number">{props.puzzleNumber + 1}</p>}
            {props.gameComplete ? <img style={{ padding: "20px" }} src={require('../icons/game-end.svg')} /> : <img src={require('../static/level-complete.svg')} />}
            <h5> {props.gameComplete ? 'You did great !' : 'Awesome !'}</h5>
            {props.gameComplete || <h6>You can proceed to the next round</h6>}
            <div className="button-container">
              {props.gameComplete || <div className="game-info">
                <div>
                  <h6>Your moves</h6>
                  <h4>{moves}</h4>
                </div>
                <div className="bar" />
                <div>
                  <h6>Perfect moves</h6>
                  <h4>{totalMoves}</h4>
                </div>
              </div>}
              {props.gameComplete && <div className="game-info">
                <div>
                  <h6>Time taken</h6>
                  {(timeTaken) ? <h4>{`${(timeTaken / 60) < 10 ? '0' + parseInt(timeTaken / 60) : parseInt(timeTaken / 60)}:${(timeTaken % 60) < 10 ? '0' + (timeTaken % 60) : (timeTaken % 60)}`}</h4> : null}
                </div>
                <div className="bar" />
                <div>
                  <h6>Total moves</h6>
                  <h4>{totalGameMoves}</h4>
                </div>
              </div>}
            </div>
          </React.Fragment>
        )
          :
          <div>
            <h5>We've added a bonus level</h5>
            <br />
            <h6>Only 1 out of 10 people have been able to solve this level</h6>
            <br />
            <h6>Click Start to play</h6>
          </div>
        }
        <button onClick={() => props.onNextLevel(props.showBonusPopup)} className={`questionOptions style-4`}>
          {!props.showBonusPopup ? <span>{props.gameComplete ? "Continue" : (props.puzzleNumber == 3 ? "NEXT" : "NEXT LEVEL")}</span> : <span>START</span>}
        </button>
        {props.content}
      </div>
      <span className="modal-infotext">{props.infoText}</span>
      {props.children}
    </ReactModal>

  );
}

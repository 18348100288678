import React from "react";
import { PUZZLES } from "./PUZZLES";
import { Board } from "./Board";
import { PuzzleList } from "./PuzzleList";
import { lsLoad, lsStore } from "./utils";
export const BOX_WIDTH = 72;

export default class Base extends React.Component {
  constructor(props) {
    super(props);
    this.selectPuzzle = this.selectPuzzle.bind(this);

    // Try to load the current puzzle from local storage
    const currentPuzzle = lsLoad("current-puzzle");
    this.state = { currentPuzzle: currentPuzzle };
  }

  selectPuzzle(i) {
    this.setState({ currentPuzzle: i });

    // Store the selected puzzle in local storage, and erase the move lists
    lsStore("current-puzzle", i);
    lsStore("current-puzzle-pos-str", i !== null ? PUZZLES[i].board : null);
    lsStore("current-puzzle-moves", []);
    lsStore("current-puzzle-future-moves", []);
  }

  render() {
    return (
      <div
        className="base-container"
      >
        <div
          style={{
            objectFit: 'contain',
            display: "flex",
            flex: 1,
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div
            className="inset-box"
            style={{
              // HACK
              width: 6 * BOX_WIDTH,
              padding: "5px",
              margin: "auto",
              fontSize: "30px",
              fontWeight: "bold",
            }}
          >
            FreeBerry
          </div>
          {this.state.currentPuzzle !== null ? (
            <Board
              key={this.state.currentPuzzle}
              puzzleNumber={this.state.currentPuzzle}
              selectPuzzle={this.selectPuzzle}
            />
          ) : (
            <PuzzleList selectPuzzle={this.selectPuzzle} />
          )}
        </div>
      </div>
    );
  }
}

import React, { Component, useEffect } from "react";
import "./splashDark.scss";
import { motion } from "framer-motion";
import SplashDarkBg from "./static/bgDark.svg";
import SplashDarkResBg from "./static/splashDarkResBg.svg";
import SmallBerryRight from "./static/smallBerryRight.svg";
import SmallBerryLeft from "./static/smallBerryLeft.svg";
import SplashMusic from "./static/splashMusic.mp3";
import { lsClear, lsStore } from "./utils";
import { fakeData } from "./fakeData";
import jwt_decode from "jwt-decode";
import FBLogo from "./freeberry-logo.png";
import { storage } from "./services/config/storage";
import { GC } from "./services/config/gamecentreSevice";
const STATS_KEY = "levelStatsUnberry";

export default function SplashDark({ beginGame }) {
  const gameDetails = storage.get.gameDetails();

  const setInitialGameState = () => {
    const initialState = {
      game: "freeberry",
      gameData: {
        levelData: [1, 2, 3, 4, 5].map((stat, index) => {
          return {
            level: stat,
            totalTime: 0,
            totalMoves: 0,
            optimalMoves: 0,
            averageMoveTime: 0,
            resetCount: 0,
            undoCount: 0,
            resetMovesStamp: 0,
            moveTimes: [],
          };
        }),
        totalLevelCleared: 0,
        totalTimeTaken: 0,
      },
    };
    lsStore(STATS_KEY, initialState);
  };

  useEffect(() => {
    const splashMusic = new Audio(SplashMusic);
    splashMusic.volume = 0.5;
    lsClear();
    setInitialGameState();
    return () => {
      splashMusic.pause();
    };
  }, []);

  return (
    <div className="splashDark-unberry" style={{ overflow: "hidden" }}>
      <motion.div
        transition={{ delay: 0.5, duration: "1" }}
        initial={{ marginTop: "350px", width: "600px", height: "150px" }}
        animate={{ marginTop: "0px", width: "195px", height: "90px" }}
      >
        <img
          src={gameDetails?.mediaUrl || FBLogo}
          style={{ width: "100%", height: "100%", objectFit: "contain" }}
          alt="play-button"
        />
      </motion.div>
      <motion.div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
        initial="hidden"
        animate="visible"
        transition={{ ease: "easeOut", duration: 2, delay: 1 }}
        variants={{
          visible: { opacity: 1 },
          hidden: { opacity: 0 },
        }}
      >
        <div className="instructions">
          <h5>🎯 Instructions</h5>
          <h6>
            A green bar is stuck amidst a bunch of bars. You’ll have to move
            bars such that the green bar gets out and goes free through the exit
            route.
          </h6>
          <h6>Try doing this in minimum moves!</h6>
        </div>
        <div className="button-container">
          <div className="game-info">
            <div>
              <h6>Levels</h6>
              <h4>4</h4>
            </div>
            <div className="bar" />
            <div>
              <h6>Time</h6>
              <h4>
                4<span>min</span>
              </h4>
            </div>
          </div>
        </div>
        <button
          onClick={() => {
            const gameData = storage.get.levelData() ?? {};
            if (gameData && !gameData?.levelData?.length) {
              GC.createGameMasterSession();
            }
            beginGame("game")
          }}
          className={`questionOptions style-4`}
        >
          <span>START GAME</span>
        </button>
      </motion.div>
    </div>
  );
}

import React, { Component } from "react";
import "./home.scss";
// import Splash from "./splash";
import SplashDark from "./splashDark";
import Game from "./Game";
import { GlobalContext } from "./context";
import { GC } from "./services/config/gamecentreSevice";
import { storage } from "./services/config/storage";

export const NEW_DATA_EVENT = "NEW_DATA_EVENT"
export default class Home extends Component {
  static contextType = GlobalContext;

  constructor(props) {
    super(props);
    //
    this.state = {
      gameDetails: null,
      isGameInIframe: false
    };
  }

  syncData = () => {
    const getGameDetails = storage.get.gameDetails();
    this.setState({ gameDetails: getGameDetails });
  };

  handleBlur = () => {
    GC.sendGameBlurredMessage()
  }

  componentDidMount() {
    storage.destroy.all();
    GC.sendGameLoadMessage();
    window.addEventListener("message", GC.messageEventHandler);
    window.addEventListener(NEW_DATA_EVENT, this.syncData);
    window.addEventListener("blur", this.handleBlur)

    const gameInIframe = window.location !== window.parent.location;
    this.setState({ isGameInIframe: gameInIframe });
  }

  componentWillUnmount() {
    window.removeEventListener("message", GC.messageEventHandler);
    window.removeEventListener(NEW_DATA_EVENT, this.syncData);
    window.removeEventListener("blur", this.handleBlur)
  }


  render() {
    const { state, setState } = this.context;
    return (
      <>
        {state.show === "game" ? (
          <Game />
        ) : (
          <SplashDark beginGame={(toShow) => { setState({ show: toShow }) }} />
        )}
      </>
    );
  }
}

import React, { Component } from "react";
import "./Game.scss";
import Header from "./Header";
import { Board } from "./Board";
import { PUZZLES as REGULAR, FREETOPLAY } from "./PUZZLES";
import { lsLoad, lsStore } from "./utils";
import { storage } from "./services/config/storage";
import bgMusic from "./static/bg-music.mp3";
import slideSound from "./static/swooshSound.mp3";
import levelCompleteSound from "./static/levelCompleteSound.mp3";
import gameOverSound from "./static/gameOver.mp3";
import GameBg from "./static/gameBg.svg";
export default class Game extends Component {
  constructor(props) {
    super(props);
    const currentPuzzle = lsLoad("current-puzzle") || 0;
    const defaultTimer = {
      minutes: 4,
      seconds: 0,
      isPaused: false
    };
    const timer = lsLoad("timer") || defaultTimer;
    const levelCompleted = storage.get.levelData()?.totalLevelCleared;

    this.state = {
      index: 0,
      currentPuzzle,
      soundEnabled: true,
      moves: 0,
      timer,
      gameMode: 'regular', // free,
      PUZZLES: REGULAR,
      isGameEnded: levelCompleted === 5 ? true : currentPuzzle === 5 ? true : false,
      lastLevelEmotion: '',
      numClose: 0,
      firstClickCloseTime: 0,
      endClickTime: 0,
      apiInProgress: false,
      bgMusic: new Audio(bgMusic),
      isBgMusicPlaying: false
    }

    this.slideSound = new Audio(slideSound);
    window.slideSound = this.slideSound;
    this.slideSound.volume = 0.5;

    this.levelCompleteSound = new Audio(levelCompleteSound);
    window.levelCompleteSound = this.levelCompleteSound;
    this.levelCompleteSound.volume = 0.07;

    this.gameOverSound = new Audio(gameOverSound);
    window.gameOverSound = this.gameOverSound;
    this.gameOverSound.volume = 1;
  }

  componentDidMount() {
    // tries to prevent browser's first touch document rule
    setTimeout(() => {
      const bgMusic = this.state.bgMusic
      bgMusic.volume = 0.5
      bgMusic.loop = true
    }, 2000)
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.currentPuzzle != this.state.currentPuzzle && this.state.currentPuzzle == 4) {
      this.setState({
        isGameEnded: false,
      })
    }
  }

  setGameMode = (mode) => {
    this.setState({
      currentPuzzle: 0,
      gameMode: mode,
      PUZZLES: mode === 'free' ? FREETOPLAY : REGULAR,
      timer: {
        minutes: 6,
        seconds: 0,
        isPaused: mode === 'free' ? true : false
      }
    })
  }

  toggleMusic = () => {
    if (this.state.isBgMusicPlaying) {
      this.pauseBgMusic()
    } else {
      this.playBgMusic()
    }
  };

  pauseBgMusic = () => {
    this.state.bgMusic.pause()
    this.setState({
      isBgMusicPlaying: false
    })
  }

  playBgMusic = () => {
    this.state.bgMusic.play()
    this.setState({
      isBgMusicPlaying: true
    })
  }

  toggleSound = () => {
    this.setState((prevState) => {
      return {
        soundEnabled: !prevState.soundEnabled,
      };
    });
  };

  setMoves = (n) => {
    this.setState({
      moves: n,
    });
  };

  resetTimer = () => {
    this.setState({
      timer: {
        minutes: 6,
        seconds: 0,
      },
    });
  };

  pauseGame = (status) => {
    this.setState({
      timer: {
        isPaused: status
      }
    })
  }

  selectPuzzle = (i) => {

    this.setState(prevState => ({ currentPuzzle: i, index: prevState.index + 1 }));
    // Store the selected puzzle in local storage, and erase the move lists
    lsStore("current-puzzle", i);
    lsStore("current-puzzle-pos-str", i !== null ? this.state.PUZZLES[i].board : null);
    lsStore("current-puzzle-moves", []);
    lsStore("current-puzzle-future-moves", []);
  };

  render() {
    // console.log(this.state)
    return (
      <>
        <img alt="bg" className="gameBg" src={GameBg} />
        <Header
          PUZZLES={this.state.PUZZLES}
          moves={this.state.moves}
          currentPuzzle={this.state.currentPuzzle}
          timer={this.state.timer}
          gameMode={this.state.gameMode}
          setGameMode={this.setGameMode}
          resetTimer={this.resetTimer}
          selectPuzzle={this.selectPuzzle}
          gameOverSound={this.gameOverSound}
          gotoNextLevel={() => { this.selectPuzzle(this.state.currentPuzzle + 1) }}
          puzzleNumber={this.state.currentPuzzle}
          endGame={(answer, fe, ec) => {
            this.setState({
              isGameEnded: true,
              lastLevelEmotion: answer,
              firstClickCloseTime: fe,
              endClickTime: ec,
            })
          }}
          setNumClose={() => {
            this.setState({
              numClose: this.state.numClose + 1,
            })
          }}
          isGameEnded={this.state.isGameEnded}
          toggleMusic={this.toggleMusic}
          isBgMusicPlaying={this.state.isBgMusicPlaying}
        />
        <Board
          numClose={this.state.numClose}
          PUZZLES={this.state.PUZZLES}
          gameMode={this.state.gameMode}
          setGameMode={this.setGameMode}
          pauseGame={this.pauseGame}
          key={this.state.index || this.state.currentPuzzle}
          puzzleNumber={this.state.currentPuzzle}
          selectPuzzle={this.selectPuzzle}
          slideSound={this.slideSound}
          levelCompleteSound={this.levelCompleteSound}
          soundEnabled={this.state.soundEnabled}
          setMoves={this.setMoves}
          moves={this.state.moves}
          resetTimer={this.resetTimer}
          toggleSound={this.toggleSound}
          toggleMusic={this.toggleMusic}
          timer={this.state.timer}
          isGameEnded={this.state.isGameEnded}
          lastLevelEmotion={this.state.lastLevelEmotion}
          firstClickCloseTime={this.state.firstClickCloseTime}
          endClickTime={this.state.endClickTime}
        />
      </>
    );
  }
}

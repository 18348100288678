const AUTH_TOKEN_KEY = "auth_token";
const GAME_KEY = process.env.REACT_APP_GAME_NAME;
const GAMEPLAY_ID = "gameplay_id";
const LEVEL_DATA_KEY = "level_data";
const GAME_DETAILS_KEY = "game_details";

const setItem = (key, data) => localStorage.setItem(key, JSON.stringify(data));

const getItem = (key) => JSON.parse(localStorage.getItem(key));

const removeItem = (key) => localStorage.removeItem(key);

const set = {
  authToken: (token) => setItem(AUTH_TOKEN_KEY, token),
  gameplayId: (id) => setItem(GAMEPLAY_ID, id),
  gameId: (id) => setItem(GAME_KEY, id),
  levelData: (data) => setItem(LEVEL_DATA_KEY, data),
  gameDetails: (data) => setItem(GAME_DETAILS_KEY, data)
};

const get = {
  authToken: () => getItem(AUTH_TOKEN_KEY),
  gameplayId: () => getItem(GAMEPLAY_ID),
  gameId: () => getItem(GAME_KEY),
  levelData: () => getItem(LEVEL_DATA_KEY),
  gameDetails: () => getItem(GAME_DETAILS_KEY)
};

const destroy = {
  all: () => localStorage.clear(),
  authToken: () => removeItem(AUTH_TOKEN_KEY),
  gameplayId: () => removeItem(GAMEPLAY_ID),
  gameId: () => removeItem(AUTH_TOKEN_KEY),
  levelData: () => removeItem(LEVEL_DATA_KEY),
};

export const storage = {
  set,
  get,
  destroy,
};

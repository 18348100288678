import React, { useState } from "react";
import Modal from "react-responsive-modal";
import Timer from "./timer";
// import { PUZZLES } from "./PUZZLES";
import { CrossIcon } from "./icons/icons";
import { lsLoad } from "./utils";
import "./Header.scss";
import MuteSpeaker from "./static/MuteSpeaker.png";
import SpeakerIcon from "./static/SpeakerIcon.png";

const Header = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [closeModal, setCloseModal] = useState(false);
  const [firstClickedTime, setFirstClickedTime] = useState(null);

  let totalMoves = null;
  const currentPuzzle = props.PUZZLES[props.currentPuzzle];
  if (currentPuzzle) {
    totalMoves = currentPuzzle.moves;
  }
  const exitButtonStyle = {
    color: "#fff",
    borderRadius: "8px",
    fontWeight: "500",
    fontSize: "18px",
  }

  const getSeconds = (ob) => {
    if (!ob || !("minutes" in ob) || !("seconds" in ob)) {
      return 0;
    }
    return (ob.minutes * 60) + ob.seconds;
  }
  const endGameAnswers = ["This level was too tough for me.", "This level had some issue/bug.", "I just got frustrated with this level.", "I just didn't enjoy this game much."];
  const renderButtons = () => {
    switch (props.currentPuzzle + 1) {
      case 5:
        return (
          endGameAnswers.map(answer => (
            < button style={{ marginTop: "30px", marginBottom: "10px" }} onClick={() => {
              props.endGame(answer, 300 - getSeconds(firstClickedTime), 300 - getSeconds(lsLoad('timer')));

            }} className={`questionOptions style-4`}>
              <span>{answer}</span>
            </button >
          ))
        );
      default:
        return (
          <button onClick={() => {
            props.endGame();
          }} className={`questionOptions style-4`}>
            <span>END GAME</span>
          </button>
        )
    }
  }
  return (
    <>
      <div className="component-header">
        <div className="center-header">
            <button className={`display-mobile ${!(props.currentPuzzle + 1 == 5) ? 'hidden' : ""}`} onClick={() => {
              if (!(props.currentPuzzle + 1) == 5) return;
              if (!firstClickedTime) {
                setFirstClickedTime(lsLoad('timer'));
              }
              props.setNumClose();
              setIsOpen(true)
            }
            }><CrossIcon /></button>

          <h2 className="chapter">{`Level ${props.currentPuzzle + 1} of ${(props.currentPuzzle + 1) == 5 ? "5" : "4"}`}</h2>
          {/* <div className="right-header">
          {totalMoves ? (
            <p className="timer v-bold text-white">
              {`${props.moves.length || 0}/${totalMoves || ""}`}
            </p>
          ) : (
            ""
          )}
        </div> */}
          <div className="right-header">
            <div className="timer v-bold text-white">
              <Timer
                level={props.currentPuzzle + 1}
                gameMode={props.gameMode}
                timer={props.timer}
                PUZZLES={props.PUZZLES}
                selectPuzzle={props.selectPuzzle}
                currentPuzzle={props.currentPuzzle}
                resetTimer={props.resetTimer}
                gotoNextLevel={props.gotoNextLevel}
                gameOverSound={props.gameOverSound}
                isGameEnded={props.isGameEnded}
                endGame={props.endGame}
              />
            </div>
          </div>
          <div className="right-header">
            {/* <motion.button
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              className="control-btn"
              onClick={() => setIsOpen(true)}
            >
              <img
                className="cursor-pointer menu-img"
                src={require("./static/menu.svg")}
                alt="FreeBerry Menu"
              />
            </motion.button> */}
            <div className="buttons">
              <span onClick={() => {
                props.toggleMusic()
                // document.getElementById("audio-player").play();
                // document.getElementById("audio-player").muted = !document.getElementById("audio-player").muted;
              }}>
                {
                  props.isBgMusicPlaying 
                  ? <img src={SpeakerIcon} alt="" />
                    : <img src={MuteSpeaker} alt="" />
                }
              </span>
              {(props.currentPuzzle + 1) == 5 ?
                <button className="display-desktop" style={exitButtonStyle} onClick={() => {
                  if (!firstClickedTime) {
                    setFirstClickedTime(lsLoad('timer'));
                  }
                  props.setNumClose();
                  setIsOpen(true)
                }
                }>{"EXIT LEVEL"}</button>
                :
                null
              }
            </div>
          </div>
        </div>
      </div>
      <Modal
        classNames={{
          overlay: "modal-component-overlay",
          modal: "modal-component-container exit-level",
        }}
        style={{ width: "auto" }}
        open={isOpen}
        center
        onClose={() => setIsOpen(false)}
      >
        {/* <PuzzleList
          PUZZLES={props.PUZZLES}
          gameMode={props.gameMode}
          setGameMode={props.setGameMode}
          selectPuzzle={(val) => {
            props.selectPuzzle(val);
            setIsOpen(false);
          }}
        /> */}
        <div className="exit-level-container">
          <h1 style={{ color: "#fff", fontSize: "30px", lineHeight: "27px", textAlign: "center", fontWeight: "bold" }}>Do you want to end this game? <br></br><br></br><span style={{ fontSize: "20px" }}>You still have time remaining to finish the challenge</span></h1>
          {renderButtons()}
          <span>END GAME</span>

        </div>
      </Modal>
    </>
  );
};

export default Header;

export const FREETOPLAY = [
  { moves: 11, board: "...... .755AA 87++19 833219 BB62.9 ..6244" },
  { moves: 11, board: "....3. ..7738 ..++38 .6611. ...255 ...244" },
  { moves: 11, board: "....66 .99884 ..++.4 111..4 .27333 .27555" },
  // { moves: 11, board: "...544 .775.. ++AB9. 66AB9. .88112 .33..2" },
  // { moves: 11, board: "...777 33...A 84++BA 8422B. 6991B. 6CC155" },
  // { moves: 11, board: "..4722 99475B 3++A5B 3..A.8 .11..8 ..66.." },
  // { moves: 11, board: ".1664. 91.B4. 9++B.. 8.3577 8.35AA .225.." },
  // { moves: 11, board: ".21188 .277.. .2++.A 5543.A 9.43.B 9.66.B" },
  // { moves: 11, board: ".22... ..16A5 ++16A5 8.4.A. 894... 897733" },
  // { moves: 11, board: ".72288 .733.B .7++.B 166CC9 1.5559 1AAA44" },
  // { moves: 11, board: "3..88B 3DD.7B A5++7B A5..11 6499CC 64..22" },
  // { moves: 11, board: "4..... 499555 4++6.. 2226.. 3311.8 ...778" },
  // { moves: 11, board: "677733 6122DD 61++49 ..8.49 BB8.4A .CC55A" },
  { moves: 11, board: "A77766 A.9333 ++94.5 .224.5 .188.. .1..BB" },
  { moves: 11, board: "D777BB D.A333 81A++4 81A5.4 81.5C9 2266C9" },
  { moves: 12, board: "..21AA 332146 C8++46 C8999. 77.... BB555." },
  { moves: 12, board: "..94CC 2294A. 8++3A5 8773B5 ..DDB1 .66EE1" },
  // { moves: 12, board: "..AABB ..11CC ++5.82 665.82 .97773 .944.3" },
  // { moves: 12, board: "..B3CC 99B3.6 82++76 825571 AA...1 .444.1" },
  // { moves: 12, board: "..CC77 999..A 5++41A 5..41B 23..1B 238866" },
  // { moves: 12, board: ".1.772 .155.2 .++..2 8..344 8.6399 8.6AAA" },
  // { moves: 12, board: ".74488 .799.6 B++2.6 B..2DD 51C233 51CAAA" },
  // { moves: 12, board: ".89995 .822.5 ++B..7 AAB..7 3.4411 366..." },
  // { moves: 12, board: "22255. 4...6. 4++A6. 119A.. ..9A88 .77733" },
  // { moves: 12, board: "35DD99 356687 35++87 AB444. AB1.2. CC1.2." },
  // { moves: 12, board: "666..4 333774 ++..94 81119. 8..2.. 8..255" },
  // { moves: 12, board: "A55588 A66BB1 A2++91 .2.491 ...477 ...333" },
  { moves: 12, board: "AAA..B 77244B ++2.8B 511.8. 5C338. 5C6699" },
  { moves: 13, board: "..88A9 .22.A9 16++A9 16.577 C335.. C44BB." },
  { moves: 13, board: ".66557 22..97 ++.39. 4A.311 4ABBDD 488CCC" },
  { moves: 13, board: "8..999 8.3322 ++76.. ..76.. 44551A ....1A" },
  { moves: 13, board: "C9995D C.225D B++831 B66831 .7.EE1 .744AA" },
  { moves: 14, board: ".22... 4.DAA7 4.D++7 11D337 8699BB 8655CC" },
  { moves: 14, board: ".3..96 .34496 ++.2.. 7772.. 1..255 1..888" },
  { moves: 14, board: ".A77.B 5A333B 5++.8B 922.8. 9.6411 ..64.." },
  { moves: 15, board: ".....8 3..6.8 3++6.8 399744 .1.722 .155.." },
  { moves: 15, board: "114..B 63422B 63++75 998C75 AA8C.5 ..8CDD" },
  { moves: 16, board: ".55722 ...739 ..++39 668839 ..1AAA ..1.44" },
  { moves: 16, board: "22..58 .97758 .9++5A 3334.A C..466 C.BB11" },
  { moves: 16, board: "557BB8 .47668 D4++.8 DE..CC 1EAA33 122999" },
  { moves: 16, board: "618844 61..55 69++CB .922CB .9733D EE7AAD" },
  { moves: 17, board: "5..888 5AAA24 ++..24 116CC4 9.63BB 9773.." },
  { moves: 18, board: ".9966. EE288D ++241D 33341D C.BB77 CAAA55" },
  { moves: 20, board: ".9.366 .9.341 8B++41 8B554. ..C777 AAC.22" },
  { moves: 21, board: "992254 ...854 1++8.4 1.A666 ..A.7. .3337." },
  { moves: 22, board: "777.A3 ....A3 ++84.3 9984.. 511666 5..222" },
  { moves: 22, board: "777.A3 ....A3 ++84.3 9984.. 511666 5..222" },
  { moves: 18, board: "388899 366..7 3++..7 2211.7 .B..55 .BAA44" },
  { moves: 20, board: ".1.66. .1.992 .1++42 77.542 A..588 A..333" },
  { moves: 17, board: "...811 33.827 ++..27 BBBAA7 599666 5...44" },
  { moves: 17, board: "...1BB .661.. .3++9. .34298 .342A8 7755A8" },
  { moves: 21, board: "77944. ..9655 ++96.2 .111.2 ....33 .888.." },
  { moves: 19, board: "55886B .99.6B ++E16. D2E177 D2CCAA .4433." },
  { moves: 16, board: ".AA.11 442..3 862++3 865573 869C7. ..9CBB" },
  { moves: 20, board: "..6744 ..6733 .++7.. 889925 ..AA25 .11BB." },
  { moves: 16, board: "AA366. .43888 54++92 541192 7...9B 7....B" },
  { moves: 20, board: ".5.111 25.883 25.++3 .66673 44A.7. ..A999" },
  { moves: 19, board: "CC5558 BB9.48 ++9.47 ..3667 ..32AA .112.." },
  { moves: 21, board: "4AAA.. 4..255 7++2.. 7166.9 B1.889 B33..9" },
  { moves: 23, board: "...155 .A.122 .A++84 .66684 ..3774 ..399." },
  { moves: 16, board: "44113A .5593A .++93. ..8677 ..8622 ......" },
  { moves: 16, board: "..6663 9944.3 ++2..5 A.27.5 A187.5 .187BB" },
  { moves: 16, board: "2AA4.. 2.14.6 ++1..6 591333 59778. .BB.8." },
  { moves: 16, board: "..422. 8841B. A++1B. A77199 66CC.D .5533D" },
  { moves: 18, board: "99BB75 C11175 C8.++5 D8.33. D4AA22 .466EE" },
  { moves: 18, board: "..59BB 2259.1 ++5..1 .488AA .43336 .777.6" },
  { moves: 16, board: "..6664 ..3BB4 ++3.C8 755.C8 7AA118 ..2299" },
  { moves: 16, board: "..1... ..16.. ++7689 2.7389 24438. ..555." },
  { moves: 16, board: "155589 122.89 .B.++6 .B.4A6 .3.4A6 .3.477" },
  { moves: 19, board: "CC555A 77BB1A .++.1D 336..D 8.622D 844999" },
  { moves: 18, board: "...AA. 98BB5. 98++5. 67225. 67.411 .334.." },
  { moves: 20, board: ".7CC.A .7222A ++.438 99.438 1.6555 1.6BBB" },
  { moves: 18, board: "3..55. 3BBAA4 ++1..4 881.6C .7226C .7999C" },
  { moves: 16, board: "..55BB ..9928 ..++28 .44AA8 ...611 77.633" },
  { moves: 16, board: "AA8.99 338.64 B.++64 BCC224 DDD1.. 77.155" },
  { moves: 21, board: "52BBCC 5288A. 4++DA. 4.6D77 ..6339 ..11.9" },
  { moves: 16, board: "44AA.. 33995. ++.157 B661.7 B.2887 CC2..." },
  { moves: 16, board: "1222BB 1.3CC. ++34.. .88477 ..AAA9 6655.9" },
  { moves: 16, board: "..1333 771.48 .++.48 995558 ..222. ..66AA" },
  { moves: 18, board: "7.44.. 755311 ++C3.9 B.CAA9 B.22.6 888..6" },
  { moves: 19, board: "6C..AA 6C7778 ++4.28 ..412B 95D1.B 95D133" },
  { moves: 18, board: "BCC453 B.8453 ++8..3 .61122 .6AA7. .99.7." },
  { moves: 20, board: "....11 A..844 A++8.. A2.8BB .23396 775596" },
  { moves: 18, board: "....33 5..266 5++2.. 87.211 87.BB9 .AA449" },
  { moves: 17, board: ".55523 ..4.23 ++4.21 .....1 .79991 .76688" },
  { moves: 16, board: "A7766. AB3335 .B++95 118.92 D.8CC2 D.4442" },
  { moves: 18, board: "55111C 88444C 7A++32 7AB.32 ..B.66 ....99" },
  { moves: 19, board: "667111 997.AA .8++.5 .84445 .BB3.5 .223CC" },
  { moves: 17, board: "66611. A44459 A.++59 288359 2..3BB 7773.." },
  { moves: 18, board: ".22217 .49917 B4.++7 B33355 ...866 ...8AA" },
  { moves: 17, board: "2..... 24448A ++6.8A ..655A 336799 1117.." },
  { moves: 17, board: "2..... 27711. ++63.. ..63.8 4443.8 .555.." },
  { moves: 19, board: "114... B54.9A B5++9A 86.33A 86.722 .6.7.." },
  { moves: 21, board: "55.B2A 1..B2A 13++2. .34... 934.77 98866." },
  { moves: 16, board: "224A.. ..4ABB 3++.7. 31557. .1.699 .886.." },
  { moves: 16, board: "B55AA. B1118. ++C28. 33C299 .44667 .....7" },
  { moves: 25, board: ".4475B ...75B .A++5. .A1C33 8.1C66 82299." },
  { moves: 28, board: "..BB8. 222785 .++715 43371C 4.6AAC ..6999" },
  { moves: 23, board: "..2888 772.1. 9++.1. 9.3655 ..36.. 4446.." },
  { moves: 23, board: ".9AA66 .92277 ++8..C BB84.C 3114.5 3.DD.5" },
  { moves: 23, board: "9.774. 9.6.4A ++61.A 3331.B 88855B ..222." },
  { moves: 21, board: ".5BB22 753331 75++.1 7448.1 ..6899 AA6..." },
  { moves: 27, board: "BAA7.. B.C766 .1C++D .1.99D 333.84 552284" },
  { moves: 22, board: ".AA5.. .C.544 9C++17 922.17 3DD.18 366BB8" },
  { moves: 21, board: "8BBBAA 8722.6 .7++.6 .11556 ...933 .449.." },
  { moves: 22, board: ".77B.. .5.B88 95++16 944.16 A33.1D ACC22D" },
  { moves: 21, board: ".3...B .3444B ++9.A5 1192A5 7.8266 7.8..." },
  { moves: 23, board: ".88..6 .33326 1++.2A 1...2A 1.4555 774.99" },
  { moves: 21, board: "..9.33 ..9222 .++1.. .AA177 555668 .BB448" },
  { moves: 23, board: ".BAA55 .B22CE ++4.CE 664338 7DD1.8 7991.." },
  { moves: 26, board: ".CC66. ..4779 ++4..9 5.4AA2 5BB1.2 .33188" },
  { moves: 23, board: "....AA 444556 ++7..6 8.7139 822139 ......" },
  { moves: 21, board: "66B993 C.B443 C++2.. 77.2AA 55888. 111..." },
  { moves: 25, board: "..AA77 1..299 1++2.5 .666.5 883..5 ..3444" },
  { moves: 21, board: "..3.44 ..3888 9++6.. 91A622 91A557 .1...7" },
  { moves: 22, board: "..B466 7.B481 7.++81 7...55 22AAA3 999..3" },
  { moves: 24, board: "A.B.CC A.B677 ++461D 334.1D .85559 .822.9" },
  { moves: 21, board: "6663.A 1..38A 1.++85 7.9985 7.2..5 7.2444" },
  { moves: 21, board: "....77 44555B A.++8B A33182 A.9182 ..9166" },
  { moves: 22, board: "..CDAA 44CD96 7.++96 7.5511 222EE3 BBB883" },
  { moves: 21, board: "2....8 2337C8 ++57CA 115..A .9BBDD .94466" },
  { moves: 21, board: ".882.. .3.255 .3.++6 944.B6 9CCCBA .1177A" },
  { moves: 22, board: ".3.55. A3.BB9 A3++29 .11429 ...477 .8866." },
  { moves: 21, board: ".4.775 .4...5 1++2.6 1992.6 ..A3.6 ..A388" },
  { moves: 24, board: "2224.. 7..499 7.++.8 1166.8 B55AAA B...33" },
  { moves: 23, board: ".AA7.. .4.766 .4++.1 22C3.1 B.C399 B55588" },
  { moves: 27, board: "6.55.. 6.AA97 ++1.97 331..B 222CCB 8844.." },
  { moves: 25, board: "222AA8 555498 ++149B 3.166B 3...CC .77..." },
  { moves: 23, board: "6.99.B 6.442B 6++321 88A321 ..ACCC 7755.." },
  { moves: 22, board: "4445.9 2215.9 ++1..3 771883 BBAAC3 .666C." },
  { moves: 27, board: "CC333. 444BB5 ++6..5 ..6.12 7.9912 788AAA" },
  { moves: 24, board: "8..1CC 877145 ++9.45 ..9BB5 222A.. 66.A33" },
  { moves: 23, board: ".94441 .97721 3++.26 355526 ..8.AA ..8..." },
  { moves: 22, board: ".73.11 A73..C A7++.C 9224.C 9.54BB 88566." },
  { moves: 21, board: ".922.. .94443 ++A.C3 88A1C. B66155 B..77." },
  { moves: 21, board: "..3... .1322. 61++85 6...85 444.7. ....7." },
  { moves: 22, board: "...11. 44486. ++7865 9.7265 9BB2.. .AA33." },
  { moves: 24, board: "5..... 5.2278 ++4.78 664..1 AAA331 99...." },
  // { moves: 23, board: "..5... B.5113 B++8.3 6..8.4 6AA2.4 77.299" },
  // { moves: 28, board: ".14488 .1..75 .1++75 .22A75 9..A33 9.66BB" },
  // { moves: 25, board: "..888B AA5..B ++5..B .49933 .42211 .6677." },
  // { moves: 23, board: "DD.CBB 777C35 9.++35 9.6885 A26... A21144" },
  // { moves: 24, board: "8.4622 8.46.9 8.++A9 CC55A9 .B7733 .B11DD" },
  // { moves: 23, board: "5557.. 4..788 4.2++3 ..29.3 6.2911 6.AAA." },
  // { moves: 21, board: "7BB66. 799.5. 1++.52 1AAA52 .433CC .4888." },
  // { moves: 22, board: ".95522 A977.C A++..C .1144C 3..6BB 3886.." },
  // { moves: 22, board: "54446. 5.996. ++236. ..2388 1113.. ...77." },
  // { moves: 22, board: "A88.36 A5..36 A5.++6 4..2.. 411299 ...777" },
  // { moves: 24, board: "999AA6 7744.6 ++5..1 2.5C81 233C8. ...BBB" },
  // { moves: 24, board: ".445.. 8885.B ++137B 66137A 2.997A 2....." },
  // { moves: 21, board: "..447. .2227. .3++6. .31.6. ..188. ...55." },
  // { moves: 26, board: "B..311 B.C3.2 ++C672 888679 44..79 .AA559" },
  // { moves: 24, board: "8.2511 8.25.4 8.++94 BB6694 .C33DD .CAA77" },
  // { moves: 21, board: "88.66. ..7255 ++72.. ..7944 .3.9.1 .3AAA1" },
  // { moves: 24, board: ".AAA26 ....26 .9++24 .9B554 89B111 87733." },
  // { moves: 21, board: ".4.177 .4.1.. .4++A2 .99.A2 8BBBA6 833556" },
  // { moves: 25, board: "45.377 45.32. 4.++2A 99B.2A ..B11. 8866.." },
  // { moves: 26, board: "2777.. 2..356 ++4356 ..415. ...188 ...99." },
  // { moves: 21, board: "2.CC88 24433D ++5..D BB5.61 A.9961 A.7771" },
  // { moves: 21, board: "..3444 ..3955 .++91. .7..16 .72216 ...88." },
  // { moves: 21, board: ".AA55. ...388 .++3.. .4.377 .4662. 11992." },
  // { moves: 22, board: ".CC5.. 8..533 8A.++D 6A.11D 6999B7 4422B7" },
  // { moves: 21, board: ".11..7 .44.87 .5++87 .5.622 3996.. 3BBAAA" },
  // { moves: 23, board: "7781.A 6.81.A 6++9.A ...944 .5.22. .533BB" },
  // { moves: 23, board: "3997.. 3.67.. ++6.A5 4488A5 222115 ......" },
  // { moves: 25, board: ".8AA44 .811.9 78++.9 7226.9 ...6BB .5533." },
  // { moves: 29, board: ".C2299 .C4448 ++51.8 7.51BB 7.336A ....6A" },
  // { moves: 24, board: "CCC22B 33351B ++.51A 6.771A 6.8.DD 44899." },
  // { moves: 21, board: ".5..22 .5666C ++1D3C 991D3B 7.443B 7.AA88" },
  // { moves: 22, board: "1886.. 1A9655 1A9++2 3337.2 ...744 ......" },
  // { moves: 24, board: "DD4447 855597 8.++92 AAC3.2 ..C311 BBC.66" },
  // { moves: 22, board: "BBB455 22.4C. 7.++C9 733119 7.A8.. ..A866" },
  // { moves: 21, board: "444C.. B..C22 B++83. B55837 9AA117 9666.." },
  // { moves: 31, board: "6661.. ..51AA ++5..8 77CC.8 2999BB 2.3344" },
  // { moves: 21, board: "55661B 99971B ++27.3 ..2AA3 4.DD.. 488CCC" },
  // { moves: 21, board: ".3..CC .39992 ++7452 BB7456 A.8856 A.DD11" },
  // { moves: 23, board: "555CC. 9977.. ++3... 2234B8 A114B8 A.6668" },
  // { moves: 22, board: "A1.BB. A1.663 .1++93 .88293 7..255 7CC44." },
  // { moves: 22, board: ".3336B ..896B ++89.. .7.115 27A..5 27A.44" },
  // { moves: 22, board: ".677AA 46BB.8 4++..8 .11998 ...522 .335.." },
  // { moves: 21, board: "A998.. A..822 7++8.6 7.5336 ..5.11 445..." },
  // { moves: 22, board: "29945. 2.745B ++74.B 88.33. 111AA. ..66CC" },
  // { moves: 26, board: "..5... ..53.6 ++53.6 911226 98847. ...47." },
  // { moves: 26, board: "82226. 8..76. ++173. ..143. ...455 ......" },
  // { moves: 21, board: "9CC55. 911.6. B++.62 B77762 .A4433 .A.88." },
  // { moves: 22, board: ".BB.66 ..5888 ++5..7 A22447 A1.9.7 A1.933" },
  // { moves: 22, board: ".888BB 1222.6 1A++.6 .A.446 .A.399 .55377" },
  // { moves: 22, board: "22.33. 8..4.. 8++49. 11549. ..5777 .666AA" },
  // { moves: 23, board: "99AAA6 5553.6 ++.3.7 288DD7 2.4.11 BB4.CC" },
  // { moves: 22, board: "..A66. 8.A222 8++3.5 .443.5 119995 ..777." },
  // { moves: 23, board: ".9666A .97BBA ++7..A 533888 5.4411 222CC." },
  // { moves: 27, board: "2.C544 2.C596 2.++96 881116 33AAA. ..BB77" },
  // { moves: 22, board: "8AADD. 86677. ++4C9. 114C95 .23335 .2BB.." },
  // { moves: 23, board: "C.444A C.199A ++1..A .62288 56DD33 577BB." },
  // { moves: 29, board: "8113AA 85532. 8.++2C 44B.2C ..B777 .6699." },
  // { moves: 24, board: "8.6644 8.CC33 ++B..2 ..B112 7775.2 99.5AA" },
  // { moves: 21, board: "9.77.. 988A52 ++1A52 .31..2 .344BB ....66" },
  // { moves: 22, board: "AA222C 333.9C ++1.96 8.1776 8.55.. BB44.." },
  // { moves: 24, board: "7.66.. 79958A ++258A ..2..1 333441 ......" },
  // { moves: 23, board: "8.229. 8.A.97 ++AB.7 444B.5 661115 ..333." },
  // { moves: 22, board: "332..9 5.2..9 5.++.4 5111.4 777688 ...6.." },
  // { moves: 30, board: ".633CC .6D.BB ++D.17 8.9917 85521A 4442.A" },
  // { moves: 24, board: "866999 8.1555 ++1.7A .4227A .4BB73 .....3" },
  // { moves: 21, board: "A77539 A.6539 ++68.9 4228.. 41.BB. 41.CC." },
  // { moves: 27, board: ".33321 ..B921 ++B9.. 4.7955 4.788A ..666A" },
  // { moves: 24, board: "64.... 64A211 ++A2.. ..A97B 58897B 5.333." },
  // { moves: 21, board: "1BB77. 1.664A 1++34A 55534A ..922. ..988." },
  // { moves: 24, board: "9661.. 9.31.. ++3.B2 AA88B2 777552 ...444" },
  // { moves: 21, board: "B38866 B3442A .3++2A .7712A ...199 ...55." },
  // { moves: 22, board: "66623A ...23A ..++3. 5.988. 54911. .477.." },
  // { moves: 21, board: "AA881C 62221C 6.7++3 9975.3 ..7544 ....BB" },
  // { moves: 23, board: "888A1. .44A1. .3++16 .399.6 ..255. ..277." },
  // { moves: 21, board: ".6BB8C .61A8C ++1A.C 5.2933 5.2977 ....44" },
  // { moves: 21, board: "...8B6 3778B6 3.4++5 224..5 .1...5 .1AA99" },
  // { moves: 22, board: ".8999C .8112C .++A2D 443A2D 6.3555 677BB." },
  // { moves: 22, board: "..4AAA 3347.9 8++719 8...19 22.5.. ...566" },
  // { moves: 21, board: "9.BB22 9....5 ++36.5 4436.7 ..8887 ..11AA" },
  // { moves: 25, board: "ABB.96 A5..96 A5++.6 8..244 833277 ..11CC" },
  // { moves: 25, board: "..331. .6661. .++.48 .29948 .2577. ..5..." },
  // { moves: 21, board: ".77.33 .444C9 ++5AC9 8.5AC6 821..6 821.BB" },
  // { moves: 27, board: "DDD77. 222CC9 B++349 B.1345 A61.45 A61.88" },
  // { moves: 21, board: "..4A66 884AC9 B.++C9 B..777 333225 111..5" },
  // { moves: 25, board: "6..944 6..975 1.++75 133375 1.8AA. 228.BB" },
  // { moves: 21, board: "93.88. 93155. ++1..7 ..1BA7 422BA7 4CC666" },
  // { moves: 27, board: ".D7744 .D8225 ++8..5 1AA63. 1BB63C 99..3C" },
  // { moves: 21, board: "4.BB.9 4.7789 4++389 66138. ..1255 .AA2.." },
  // { moves: 23, board: "CC777. 444559 ++.219 833216 8.A.16 8.A.BB" },
  // { moves: 22, board: "1555CC 1266.8 32++.8 344998 ...7AA .BB7.." },
  // { moves: 29, board: "33222. 44411A ++8..A 5587C9 B667C9 B....." },
  // { moves: 21, board: ".6.... .6..2. .6++2. 44512. ..5177 .3388." },
  // { moves: 27, board: "2.6669 2...79 2++.78 553.78 ..3AAA 4411.." },
  // { moves: 26, board: ".18877 .133.2 .1++.2 AA54.2 B.5499 B666.." },
  // { moves: 23, board: "A58844 A5113. A5++3C 622.3C 6..977 BB.9.." },
  // { moves: 21, board: "D66BB5 D77.35 C++.32 C99932 C8111. .844AA" },
  // { moves: 23, board: "A9933. A..5BB ++.58. 77748. .1248. .1266." },
  // { moves: 21, board: ".4446. 711163 7.++63 9..288 9.52.. AA52.." },
  // { moves: 22, board: "..AA.8 4441.8 ++31.6 B.3..6 B.5599 ..7722" },
  // { moves: 24, board: "222668 DD7798 4.++91 4AA5.1 ..E5CC BBE33." },
  // { moves: 27, board: "1884.. 1C34DD .C3++9 .66.A9 222.A5 BBB775" },
  // { moves: 22, board: "..466. 3.4.5. 3++.5. 11995. .8777. .822.." },
  // { moves: 21, board: ".69933 .6BB.C ++7..C 557A84 122A84 1.DD.." },
  // { moves: 21, board: ".11167 ....67 .5++43 .58.43 ..822. ......" },
  // { moves: 24, board: "9AA222 9.3566 ++351. .77.18 .44418 ......" },
  // { moves: 23, board: ".88AA. 99926. .++265 .73445 .73115 ......" },
  // { moves: 23, board: "1122D. 6667D4 ++.7.4 E99BB5 E.3CC5 AA3.88" },
  // { moves: 22, board: "BBB22C 55511C ..3++6 9.38.6 9..877 ..AA44" },
  // { moves: 21, board: "999AA. 4388C. 43++C7 466227 B..511 B..5.." },
  // { moves: 21, board: "11177. 222BB. ++8..9 CC83A9 5443A. 5.666." },
  // { moves: 22, board: "5..... 5.2211 ++4..8 774A.8 333A.6 ..99.6" },
  // { moves: 21, board: ".12227 .1B..7 ++B.4C 33994C 6.8855 6AA..." },
  // { moves: 21, board: ".6CCDD .61444 ..1++2 77B.A2 8.B5A9 8335A9" },
  // { moves: 22, board: ".BB.CC .88857 ++1257 33125A 9.6..A 9.6.44" },
  // { moves: 22, board: "..3338 99A..8 7.A++8 74441. 7..21. 66.255" },
  // { moves: 24, board: "AA.B.. 5..BC. 52++C7 1244C7 12899. 33866." },
  // { moves: 27, board: ".BB7.. .A.733 6A++.D 69992D 455.28 4CC118" },
  // { moves: 25, board: "1BCCC3 1B...3 ++8.7A 228.7A 6.8555 699444" },
  // { moves: 23, board: "..2333 ..2887 ++56.7 AA5649 1BB649 1....." },
  // { moves: 30, board: "22.1.. 6551.7 6.8++7 338..7 ..8444 ..999." },
  // { moves: 23, board: ".9222D 79116D 7++C6B 533C6B 5.4888 ..4AA." },
  // { moves: 24, board: ".7..11 A7666B A7++.B 5543.B ..4388 .9922." },
  // { moves: 21, board: "...844 ..28.B 6.2++B 6..111 AA3335 777995" },
  { moves: 22, board: ".211A. .24.A. ++4..8 399958 36675. 3..7.." },
  { moves: 21, board: "333884 555..4 ++6..A 2.6.1A 2.991. .777.." },
  { moves: 23, board: ".4443. ..773. .++.28 ..1.28 .51998 .566AA" },
  { moves: 22, board: "AB3355 AB2267 CB++67 C88.67 ...411 .994.." },
  { moves: 26, board: "DD7771 44CCA1 ++..A3 ..2663 9.2.88 955BB." },
  { moves: 22, board: "64.999 6411.5 84++.5 877B.5 8..B33 ..22AA" },
  { moves: 27, board: "13.AA. 13..2C 9D++2C 9D552C 9D6B77 886B44" },
  { moves: 22, board: ".33364 ....64 .A++62 .A5..2 7A5.11 79988." },
  { moves: 21, board: "9B.888 9B.655 9++6A7 ..33A7 ..1447 221..." },
  { moves: 23, board: ".B6668 .B9..8 ++9..4 119AA4 .3332. 77552." },
  { moves: 23, board: "DDD4CC 55.466 ++.4BE 8.77BE 8.2AA9 332119" },
  { moves: 22, board: ".66888 9933C5 .++.C5 ..177B AA144B DD222B" },
  { moves: 21, board: "..AAA. 772.5. ++285. .368.. 436BB. 411999" },
  { moves: 23, board: "A.221. A.5.1. ++5..4 BB33.4 777994 .66888" },
  { moves: 25, board: "...... ..2388 ++2357 6..357 61114. ....4." },
  { moves: 22, board: "88444B 6662.B ++.239 A.5539 A.1CC9 771..." },
  { moves: 21, board: "..222. .88.1. ++3.1. 9.355. 977666 44...." },
  { moves: 27, board: "4...3. 46663. ++1875 ..1875 ...922 ...9.." },
  { moves: 28, board: "CCC442 111992 D.++B7 D.88B7 ..6355 EE63AA" },
  { moves: 23, board: "4..166 4..17B 4.++7B 332.7. ..2889 .55AA9" },
  { moves: 28, board: "884B.. 9.4B27 93++27 13AAA7 1..566 ...5.." },
  { moves: 21, board: "..77.. 335.88 ++5..9 115669 444A.. ...A22" },
  { moves: 24, board: "445559 222379 ++.37. 166AAB 1.8..B 1.8.CC" },
  { moves: 21, board: "887771 4.5531 4.++3C 99BA3C ..BA66 ...22." },
  { moves: 25, board: "7772.. 9..2CC 9.++.A 5511.A 4BB666 4.8833" },
  { moves: 21, board: "B.555. B.3399 ++6..4 226.74 .11174 .AA.88" },
  { moves: 22, board: "..2244 333CC9 ++..19 7.AA1B 7.851B 7.8566" },
  { moves: 21, board: ".34477 A35558 A3++.8 .662.8 ...211 .999.." },
  { moves: 21, board: "..A333 ..A119 ++85.9 6.8524 677524 ......" },
  { moves: 23, board: ".....9 333119 2.++B7 2.66B7 ..5844 AA58.." },
  { moves: 23, board: ".7..44 67..BA 67++BA 8835.A ..3599 .2211." },
  { moves: 21, board: ".A2299 .A355C ++3.BC 1..6B4 1886B4 ..777." },
  { moves: 22, board: ".4555. .486BB ++862A 39962A 3CC.1D EE771D" },
  { moves: 23, board: "..5599 B77CC8 B.++18 .A4418 .A.233 .662.." },
  { moves: 21, board: "1..2BB 1.42.. 1.4++A 7733.A .8666A .89955" },
  { moves: 28, board: ".7773. ....3. .++.1. .5881. .5644. 226..." },
  { moves: 22, board: "114458 92AA58 927++8 CC73.. ..73DD BB666." },
  { moves: 24, board: "222449 333B89 ++.B86 A11CC6 A..777 A..555" },
  { moves: 21, board: "..A665 3.ADD5 3.++7C 38897C 2B.944 2B.111" },
  { moves: 23, board: ".22236 ..AA36 14++35 14...5 1.7.88 BB799." },
  { moves: 22, board: "...399 AA435. ++425. 1142CC 8.77.B 8666.B" },
  { moves: 22, board: ".4.77. .45568 .4++68 221.6. ..133. .99AA." },
  { moves: 22, board: "CC222. ..A999 ++A7.. 331766 551884 DDBB.4" },
  { moves: 21, board: "8859.. .359AA 73++12 7BB612 ...6DD ..44CC" },
  // { moves: 21, board: "488833 4.22.9 4.A++9 ..A559 ..A677 1116.." },
  // { moves: 26, board: ".45557 .4..A7 ++6.A7 2.6888 211333 ..99BB" },
  // { moves: 24, board: "B.443. B.8.3. ++87.9 1117.9 AAA229 .66555" },
  // { moves: 23, board: ".ACCCD .A661D ++2917 3329B7 855.B. 8..44." },
  // { moves: 27, board: ".52299 .588.4 15++34 1CCA34 ...ABB ..6677" },
  // { moves: 29, board: "44499. 557778 ++6..8 ..6B3A C11B3A CDD222" },
  // { moves: 22, board: "777229 444..9 ++3.16 8.3.16 8.AA1. ..555." },
  // { moves: 26, board: "133A4. 1.5A4. ++5.49 .68889 .62277 .BBB.." },
  // { moves: 22, board: "97.666 97...1 .7++41 .22541 ...5AA .8833." },
  // { moves: 28, board: ".66AAA 44.533 ++.5.9 1777.9 1.8CC9 228BB." },
  // { moves: 23, board: "6.BBB. 6.888. ++.3A1 2223A1 554491 ..779." },
  // { moves: 23, board: "119995 A66.45 A2++4. A2.833 7DB8.C 7DBEEC" },
  // { moves: 22, board: "CCC22. .44A.. ++BA.1 6.B551 6.997. 88337." },
  // { moves: 22, board: "3.BCCC 3.B55A ++B17A .DD179 .22849 666849" },
  // { moves: 28, board: ".8887. ....7. .++.4. .1224. .1366. 553..." },
  // { moves: 29, board: "..266. 1.299A 1++45A .BB45. 77743. .8883." },
  // { moves: 26, board: "1..... 144428 7++328 76.3.5 76B995 AAB..." },
  // { moves: 25, board: "333119 B.4AA9 B.4++9 B22788 ...7.. .6655." },
  // { moves: 21, board: "...77. 33..16 5++.16 52221. 884... ..4999" },
  // { moves: 22, board: ".44655 .9.6.A .9++3A .8883A ..2111 772.BB" },
  // { moves: 22, board: "88662. 111C2. ++AC2B ..A55B 4.33.. 49977." },
  // { moves: 21, board: "8.994. 8.5.4. ++5..7 11...7 666337 ....22" },
  // { moves: 23, board: "..99CC 77333A ++.4BA E884B5 E.2115 DD2.66" },
  // { moves: 23, board: ".811AA B82227 B++..7 .33997 ...455 .664.." },
  // { moves: 23, board: ".3DD77 13999A 1++68A BB468C ..422C ..4555" },
  // { moves: 30, board: ".12666 .12..A ++27.A 4557.9 4....9 883339" },
  // { moves: 21, board: "8.2221 8...31 5++.39 544439 ..6.77 ..6.AA" },
  // { moves: 23, board: "44AA68 395568 39.++8 3221D. CC71D. ..71BB" },
  // { moves: 29, board: "4.88.. 4DD725 ++3725 BB3..9 CCC119 66AA.." },
  // { moves: 24, board: "99.778 222448 ..++.3 B.6AA3 B.6155 .CC1.." },
  // { moves: 21, board: "....BB 222556 8.++36 844739 8.A739 ..A711" },
  // { moves: 22, board: "B8.66. B8.AA2 B8++72 .99172 3..144 3CC55." },
  // { moves: 21, board: ".2.AA. .2..67 .2++67 85516. 8..199 8.3344" },
  // { moves: 25, board: "99.8.3 45.8.3 45++.3 4772.. ..62AA ..6111" },
  // { moves: 25, board: ".7AA93 .7BC93 ++BC53 82215. 8..1.. ..4466" },
  // { moves: 21, board: "62233. 6.7... ++754. .9954. 11154. ..88.." },
  // { moves: 25, board: ".99955 .6CCCB 26++.B 28844B ..3A11 773A.." },
  // { moves: 25, board: "8BB54. 8.6541 ++6..1 999771 ..CC33 .222AA" },
  // { moves: 22, board: ".CCCD3 9A66D3 9A2++7 4421.7 B..155 B88.EE" },
  // { moves: 23, board: "...2CC 3442.A 3.7++A ..7111 55666B .8899B" },
  // { moves: 22, board: "666887 55.1.7 ++.1C3 2B99C3 2BA.C. 2.A44." },
  // { moves: 23, board: "BB877. ..8.99 ++8..A 455.2A 43332A 11..66" },
  // { moves: 24, board: "4..599 4..58. 7.++81 7AAA81 7.B222 33B66." },
  // { moves: 24, board: "755111 733B.9 ++.B.9 866229 8A..4. .ACC4." },
  // { moves: 22, board: "4446.B 2236AB ++3.A8 755.18 7.9918 ....CC" },
  // { moves: 31, board: "33311B CC22.B ++59.B ..59AA 8.776. 84446." },
  // { moves: 24, board: "9.2A11 9.2A.D 9.++5D 44885D .377BB .3CC66" },
  // { moves: 24, board: ".BB6.. .83677 .83++9 C11159 C22.5A ...44A" },
  // { moves: 24, board: "B..533 B44526 B.++26 119.2A ..988A .77CC." },
  // { moves: 21, board: ".88.11 .7774A .3++4A .32.49 .32669 .555.." },
  // { moves: 22, board: "8553.. 8.73.1 ++7..1 .22661 .9AABB .9..44" },
  // { moves: 21, board: "22.478 1..478 1++.5B 669.5B ..9CCC .AA33." },
  // { moves: 22, board: ".A..77 .A8..3 6B8++3 6B4413 .B.21. .99255" },
  // { moves: 24, board: "....33 44482C ++A82C 55A.29 7.1119 7.BB66" },
  // { moves: 21, board: "CCC558 339..8 6.9++8 677B44 AA.B11 ....22" },
  // { moves: 27, board: "7AAA1. 7..41. ++5426 ..5B26 ...B33 99.88." },
  // { moves: 22, board: ".AA777 .22.18 5++.18 544BB8 5C9933 .C..66" },
  // { moves: 21, board: "755AA. 7.BB91 ++.391 44439. ..622. ..6888" },
  // { moves: 21, board: ".6.... .6.77B .6++AB 5334AB 5.1488 22199." },
  // { moves: 22, board: "112... ..2888 .++4A. 6694A5 .39775 .3.BB." },
  // { moves: 21, board: ".9.77. .9..5. 89++5. 84435. 2..311 2AA66." },
  // { moves: 23, board: "222.C4 9.55C4 9.A++3 11A..3 .788.3 .766BB" },
  // { moves: 26, board: ".88A6. 777A6. .4++6. .41.99 541..B 53322B" },
  // { moves: 25, board: "A88BBB A44..9 A++.19 776619 .C222. .C3355" },
  // { moves: 22, board: "..6664 11A..4 ++A..4 .79933 .78822 ....55" },
  // { moves: 22, board: "711283 7..283 ++5.8A .B566A .B44CC ..999." },
  // { moves: 21, board: ".33325 ..8.25 ++8... 67B111 67B4AA .994.." },
  // { moves: 21, board: "..1777 991.4. 5++.4B 5...AB 66.2A. .33288" },
  // { moves: 32, board: "B177CC B1..5. B1++5A 89925A 8..233 66.44." },
  // { moves: 23, board: "..AAA6 228..6 ++8..6 9433BB 941177 ...555" },
  // { moves: 24, board: "444889 .....9 ++1..5 6.1AA5 6.772. ..332." },
  // { moves: 21, board: "..633. C.6555 C++A.4 81.A.4 81999B 2277.B" },
  // { moves: 21, board: "CC.... 4441B5 ++71B5 997.A. 6.88A2 6.3332" },
  // { moves: 21, board: "..2224 ..1..4 ++13.7 6653.7 9.5388 9....." },
  // { moves: 21, board: "B.7.AA B.7855 ++18.C .21..C .24443 .66993" },
  // { moves: 21, board: "..4866 ..48.2 ++4..2 399AA1 3777B1 55..B." },
  // { moves: 21, board: "6.4AAA 6.4877 3++821 3...21 .B5551 .BCC99" },
  // { moves: 21, board: "666.2. 5...2. 51++89 .17.89 ..744. ...33." },
  // { moves: 26, board: "462288 46..9. 46++95 .77195 ...133 .AAA.." },
  // { moves: 23, board: "...... 8444.5 8.++75 332175 ..2199 6661.." },
  // { moves: 23, board: "4DDAB3 4..AB3 ++C.B5 .8C775 .82266 .9911." },
  // { moves: 26, board: "44.A.3 .7.A13 .7++13 .886.. ..5622 ..5999" },
  // { moves: 22, board: ".3.44. .3.77A .3++8A 51198A 5..922 ..66BB" },
  // { moves: 29, board: "...8AA ..1877 ++134. 9..34. 92226. .5556." },
  // { moves: 26, board: "4.BB.. 4.AA3. ++5.37 115..7 888997 ..6622" },
  // { moves: 21, board: "1AA526 1..526 .8++.6 78.B33 7..B44 .CC99." },
  // { moves: 23, board: "..BBB. 883.1. ++3917 ..49.7 .24667 .2AA55" },
  // { moves: 23, board: ".2444D .2377D ++3.59 BBAC59 6.AC11 6.88.." },
  // { moves: 22, board: ".64499 .688.7 .++..7 BAA117 B..233 .552.." },
  // { moves: 27, board: "BBB66. 332221 ++C..1 8.C7A9 8557A9 ...444" },
  // { moves: 21, board: "71133. 722.4. C++.45 C88845 .A99BB .A666." },
  // { moves: 22, board: "AAA53B 88253B ++2..4 ..2774 .116.4 9996.." },
  // { moves: 22, board: "77733. ..11BB ++5..6 8.5CA6 899CA2 44...2" },
  // { moves: 23, board: "6..988 6339.4 ++1AB4 ..1AB5 ..2..5 ..2775" },
  // { moves: 27, board: "322B.. 349B66 .49++D .CC.AD 777.A1 .88551" },
  // { moves: 22, board: ".511AA .599.2 .++..2 .88442 ...733 .667.." },
  // { moves: 21, board: ".2.77. .2..38 .2++38 11.53. 6..544 6999.." },
  // { moves: 21, board: ".38811 .39554 ++9..4 677224 6..ABB ...A.." },
  // { moves: 26, board: ".44999 1113B. ++.3B. A773B6 A.5886 225..." },
  // { moves: 28, board: ".BBBCC 883554 D.3++4 D.7774 D996AA .22611" },
  // { moves: 21, board: ".221.. .3.166 .3.++B CAA.9B C44495 .77885" },
  // { moves: 22, board: ".3.77. .3BB69 .3++69 42216. 4..188 4.55AA" },
  // { moves: 23, board: "AA666. 7553.B 7++3.B 994... ..4888 CC2211" },
  // { moves: 21, board: "...... 6..844 6++87. .2227. 553.7. ..3111" },
  // { moves: 27, board: "..3555 AA37.. .++7.. .21886 .21996 44BB.6" },
  // { moves: 22, board: ".1388. 513... 51++2. 994.2A ..4.7A .6667A" },
  // { moves: 24, board: "466C2. 4.BC23 ++B..3 .99883 .5AA77 .5..11" },
  // { moves: 22, board: "77755. ..9966 ++3..D 2.3A1D 2CCA1B 88844B" },
  // { moves: 21, board: "...788 93.74. 93++4B 51114B 5.62AA ..62.." },
  // { moves: 22, board: "C.8.66 C.89AA ++2914 ..2.14 .37775 .3BBB5" },
  // { moves: 21, board: "444AA1 2229B1 ++.9B. 755336 7.88.6 7.CC.." },
  // { moves: 22, board: "..5A11 995A7D C.++7D C.3344 226668 BB...8" },
  // { moves: 23, board: "111658 ..3658 ++3A47 2CCA47 2.BB4. ...99." },
  // { moves: 23, board: ".94488 39777B 3++..B .AA22B ...166 .551.." },
  // { moves: 23, board: "AAA488 22.4BB ++.431 C.9931 C.677E DD655E" },
  // { moves: 23, board: "77733. 55CC8. ++.98. ..19BB A.144. A2266." },
  // { moves: 25, board: "9888BB 94.553 .4++73 .46673 ...2AA .CC211" },
  // { moves: 26, board: "...555 444AA3 ++1..3 ..196C B8896C B77222" },
  // { moves: 23, board: "14.999 1477.6 .4++B6 .552B6 ...233 ..88AA" },
  // { moves: 23, board: ".5..66 .5..71 .5++71 99A4.1 ..A488 .2233." },
  // { moves: 22, board: "C991B8 C..1B8 ++3.B6 .A3446 .A7755 ...22." },
  // { moves: 22, board: "..333B 9.A..B 9.A++B 88225. .4775. .41166" },
  // { moves: 23, board: "B222.. B..166 A++1.. A57738 954438 9CC..8" },
  // { moves: 22, board: "AA777. .22286 ++5.86 1.538C 1B43.C 1B4399" },
  // { moves: 21, board: "5..... 5.77A. 5++8A6 2248A6 ..4336 9911.." },
  // { moves: 21, board: "..C333 66C58B A++58B A22144 ...199 7771.." },
  // { moves: 22, board: "..2111 ..259. .++596 8A4496 8A3... .A377." },
  // { moves: 22, board: "47.AA. 47..1. 4++.13 665.13 ..588. 9922.." },
  // { moves: 21, board: "..6BDD 886B49 C.++49 C..555 111223 AA7773" },
  { moves: 21, board: "79933. 7C66AA 4C++D. 4B21D. .B2188 5521.." },
  { moves: 21, board: ".88.66 ..2444 ++23.. 5573BB CC711A .99..A" },
  { moves: 32, board: ".39966 .3.A.. .++A14 .55514 ..2884 772..." },
  { moves: 25, board: "888.5. 62..5. 62++39 771.39 ..144. ......" },
  { moves: 22, board: "5999AA 5.88.6 .3++.6 .3.446 .3.722 .BB711" },
  { moves: 21, board: "...3DD 8443.9 87A++9 87A665 222.C5 BB11C." },
  // { moves: 21, board: "..8..5 ..8AA5 6.++43 622243 B1.977 B1.9CC" },
  // { moves: 22, board: ".1122. 557996 ++7B.6 .AAB33 ..C888 ..C444" },
  // { moves: 21, board: ".99.2. 81772. 81++25 AB4CC5 AB466. ..333." },
  // { moves: 24, board: "666CC3 551173 ++A.78 2.AB.8 299B.. 444..." },
  // { moves: 23, board: "33399. .6655. ++4... A.4177 A2218. ....8." },
  // { moves: 22, board: "77366. ..3444 59++D8 5922D8 .9.CCA BB111A" },
  // { moves: 23, board: "111.96 8...96 83++47 .35.47 ..522. ......" },
  // { moves: 21, board: "B88AA. B.7764 B++564 999564 ..1333 ..122." },
  // { moves: 21, board: "AA9993 662213 ++.71B C5571B C.8... C.844." },
  // { moves: 22, board: ".77994 ..ABB4 ..A++3 1.58.3 1.5866 ...222" },
  // { moves: 26, board: "..3555 ..3462 .++462 .89962 .8.AA. .1177." },
  // { moves: 21, board: "22C.88 44C..7 B6++D7 B699D7 B6.533 .AA511" },
  // { moves: 23, board: "3.1115 3.4CC5 ++4..5 .9AA22 .988BB .7766." },
  // { moves: 23, board: ".D22AA .D6661 7D++.1 793.44 893.C5 8BBBC5" },
  // { moves: 21, board: ".C5552 .C11D2 ++36D. 4436.. 7AA6BB 7.9988" },
  // { moves: 23, board: "5.DDBB 5.7719 ++C819 ..C8AA ..4446 333226" },
  // { moves: 22, board: ".15554 .1B664 ++B..4 3777AA 3...99 22288." },
  // { moves: 24, board: "3.BB8. 3.7.8. ++76.1 2226.1 999AA1 ..4455" },
  // { moves: 22, board: "888.65 9...65 92++63 .2A773 1.ABBB 1.444." },
  // { moves: 26, board: "552... ..2..3 ..2++3 8884.3 711499 7..66." },
  // { moves: 21, board: "...BBB 552229 ++..79 866.74 8.1AA4 CC1.33" },
  // { moves: 23, board: "A.7775 A.9CC5 ++9..5 .14422 .133BB .8866." },
  // { moves: 21, board: ".77769 ....69 52++64 528..4 .28333 .111.." },
  // { moves: 21, board: ".7AA55 .76444 ++6.8B .33.8B 99111B ..222." },
  // { moves: 23, board: ".7775. .4..5. .4++63 228.63 ..811. ......" },
  // { moves: 25, board: "335777 ..5444 ++9... ..9BBA 6112.A 6882.." },
  // { moves: 26, board: ".21166 .2..57 .2++57 AA3.5. ..399. .4488." },
  // { moves: 25, board: "6661.A 77715A 9.++53 9.B.53 2.B444 2.888." },
  // { moves: 24, board: "..7555 3.74.. 3++4.. 3114AA ..8226 998..6" },
  // { moves: 21, board: "55496. ..496. 7++86. 71.8.A .1.22A ...33." },
  // { moves: 24, board: "..999. .8813. ++713A 5.766A 5.BB4. 22..4." },
  // { moves: 22, board: "66..5. 111259 ++A259 33A88B 4.CC.B 4.77.." },
  // { moves: 27, board: ".1BB44 .1.9AA .++9.2 3.6CC2 3.6882 35577." },
  // { moves: 21, board: ".366.A .3111A ++C.94 55C.94 8.7722 8.BBDD" },
  // { moves: 24, board: "...AAA 111227 ++3..7 663.85 9.4485 9....." },
  // { moves: 21, board: "99966C 55AA.C ++1... 8.13DD 8773.4 22.BB4" },
  { moves: 23, board: "..88DD 44777A ++.69A E11693 E.5223 CC5.BB" },
  { moves: 28, board: "76612. 7.412. ++4138 .99938 555... ..AA.." },
  // { moves: 23, board: "..6633 444BBA ++1..A 7.1.82 7.9982 ....55" },
  // { moves: 21, board: "2..88. 21..73 91++73 9.557. 9.644B AA6CCB" },
  { moves: 21, board: "..2777 112.59 .6++59 86335A 86.B.A ...B44" },
  { moves: 21, board: "22.9.. .6.977 .6++.3 88B..3 55B..4 .11AA4" },
  { moves: 24, board: "AA..CC 33366B 8++.7B 8.4.75 214.75 214.99" },
  { moves: 22, board: "..7666 .27.C3 .2++C3 58BB99 58AAA4 .11..4" },
  { moves: 22, board: "..8881 .99921 ++A.25 3.A4.5 3.7466 ..7..." },
  { moves: 24, board: "3888.. 3..611 ++.6.B 5226.B 5.7994 AA7..4" },
  { moves: 23, board: ".A2277 9A.155 9++1.. CC6DDB 8.633B 8.644." },
  { moves: 24, board: "AAA995 771145 ++3.48 B.36.8 B..6CC 22...." },
  { moves: 31, board: "7774.. ..C422 ++C..8 AA99.8 166633 1.55BB" },
  { moves: 23, board: "16.777 16BB.9 16++29 .AA529 ...533 .8844." },
  { moves: 28, board: "CCC55. 666BB4 ++A..4 11A.72 3.8872 3.99.." },
  { moves: 22, board: ".BBB85 A67785 A6D++2 44DC.2 9..C11 9EE.33" },
  { moves: 29, board: "554... 8.4AAA 8++2.. 99.2B6 3377B6 ..1116" },
  { moves: 23, board: ".3BB77 .3A.8. ++A48. 611422 6554.C ..99.C" },
  { moves: 27, board: "...677 ..1639 ++1A39 5..A4. 58884. ..222." },
  { moves: 21, board: "..2229 88AA79 .++.76 44CB76 D.CB33 D1155." },
  { moves: 22, board: "A22544 A7.53. A7++39 .88.39 ..166. BB1..." },
  { moves: 21, board: ".71.33 .7144A .71++A 2558.A 2..866 2999.." },
  { moves: 25, board: ".977AA .988.B .9++.B 2445.B 2..533 ..1166" },
  { moves: 22, board: "..447. .9997. .++.65 ..2.65 .1288. .1333." },
  { moves: 31, board: "38811. 3.95CC ++95.. .6642B AAA42B ..772." },
  { moves: 23, board: "BB9994 7.66.4 7++2D3 7552D3 ..A113 CCA.88" },
  { moves: 23, board: ".99A.. 111A.8 ++52.8 3352.. 7.4466 7..BB." },
  { moves: 24, board: "B577A3 B5..A3 ++142C ..142C ..D82C 66D899" },
  { moves: 23, board: ".C777A .C115A ++D453 88D423 9BB42. 9.66.." },
  { moves: 23, board: ".99645 .A3645 .A3++5 8222.. 8..177 ...1.." },
  { moves: 22, board: "DEE446 D22.B6 3++.B5 3111B5 .7CC88 .799AA" },
  { moves: 22, board: "..B1CC 66B19D 8.++9D 8.EE44 777223 55AA.3" },
  { moves: 22, board: "12.88. 12.44B C2++7B C33A7B 6..A55 6.99DD" },
  { moves: 21, board: ".25544 .27778 .++1.8 99A1.B ..A33B ..A666" },
  { moves: 23, board: "2.5AAA 2.53B. 9++3B7 98CC.7 984446 111DD6" },
  { moves: 22, board: ".22.77 ..3BBB ++3..A 94455A 91.8.A 91.866" },
  { moves: 23, board: ".66628 533.28 59++2C .9A44C BBA.11 ...77." },
  { moves: 24, board: ".599.. 85...D 83++6D C3446D C312AA BB1277" },
  { moves: 28, board: "...7.. ..2766 ++2195 3..195 344498 .....8" },
  // { moves: 25, board: ".ABB44 .A1..3 .A1++3 6779.3 6..955 ..2288" },
  // { moves: 22, board: ".6BB55 .633.8 .++..8 7AA998 7..244 .112.." },
  // { moves: 24, board: "944433 9..BB2 9++.A2 5588A2 .1.... .17766" },
  // { moves: 24, board: "..3.1. 773.19 ..3++9 8882.9 6..244 6AA55." },
  // { moves: 25, board: "DDA8.. 2.A8BB 2++76. 33476C ..499C .1155." },
  // { moves: 22, board: ".577BB .523AA ++23.. 499.8. 4.118. 66..8." },
  // { moves: 22, board: "B555AA B.88.7 B6++.7 .6.117 .6.322 .99344" },
  // { moves: 21, board: "B.AA66 B.1.C4 ++1.C4 3D9922 3D8885 .77..5" },
  // { moves: 21, board: "9.22AA 9.6.37 ++6.37 544.DD 5.888C 11BB.C" },
  // { moves: 22, board: "555881 33...1 ++7..9 A.7.29 A.4429 ....66" },
  // { moves: 22, board: ".32211 .39997 .++..7 .55AA7 ...688 .446.." },
  // { moves: 27, board: "BB8881 225551 C.++97 C.AA97 ..43DD 6643.." },
  // { moves: 22, board: "66AAA. 5..422 5++4.. 778331 ..8..1 ..8999" },
  // { moves: 24, board: "BBB66C 1199.C ++5..8 4.53D8 4AA3D. 77722." },
  // { moves: 21, board: "....33 599974 56++74 .68.72 .68112 ...AAA" },
  // { moves: 25, board: "..999. 71163. 7++63. C2553A C2844A ..8BB." },
  // { moves: 21, board: ".44A.. .B.A55 .B.++C 688.2C 611127 .99337" },
  // { moves: 21, board: "2BB998 244578 ++.578 .3117. .3C666 AAC..." },
  // { moves: 21, board: ".BB2.. .6.233 .6++74 .AA.74 511.78 599CC8" },
  // { moves: 22, board: "DD2223 99..73 ++..75 166445 188.AA 1BBCC." },
  { moves: 27, board: ".CC5.. .D6544 .D6++1 888.21 B77.23 B99AA3" },
  { moves: 22, board: ".CC1.. .441A3 92++A3 92DDA5 .26885 776BB5" },
  { moves: 22, board: "8AAB4. 8.5B41 ++5..1 222331 ..7... 997.66" },
  { moves: 22, board: "244BB. 211189 37++89 376689 ...5AA CCC5.." },
  { moves: 21, board: ".5BBAA .53337 .59++7 889CC4 1.DD.4 1.2266" },
  { moves: 23, board: "764488 76..9. 7++.9A 553.9A ..3111 ..22.." },
  { moves: 23, board: ".2.99. 82.BB6 82++56 144356 1.D3AA 77DCC." },
  { moves: 23, board: "9998.. .1186. ++2.64 7.2334 7.55A4 ....A." },
  { moves: 24, board: "....AA 444556 ++8..6 9.8312 977312 ....BB" },
  { moves: 30, board: "A7776. A.116. ++2439 ..2439 ...588 ...5.." },
  { moves: 24, board: "87711. 85524. ++A24. ..AB99 333B.6 ..CC.6" },
  { moves: 27, board: "BB888. 22255A ++1..A 331.69 7.4469 7....." },
  { moves: 24, board: "..488. 5.4993 5++AB3 ...AB2 1117.2 ...766" },
  { moves: 23, board: "58821. 5.921. ++9..A 33...A 77744A ....66" },
  { moves: 21, board: "22233. 77668. ++9.81 ..9441 C.55.1 CBB.AA" },
  { moves: 22, board: ".3..CC .35599 ++A..8 22AB18 677B14 6....4" },
  { moves: 21, board: ".8BB33 .8669A ++.59A 4445.1 D.2221 D.77CC" },
  { moves: 29, board: "188829 1..629 ++36AB ..37AB ...755 .44CCC" },
  { moves: 21, board: ".66.55 11148A ++C48A 99C..B 7.222B 7...33" },
  { moves: 23, board: "2228.6 ..18A6 ++1.A9 5444.9 5BB3.. ...377" },
  { moves: 26, board: "555886 993376 ++.C76 ..2C11 A.2BB. ADD44." },
  { moves: 23, board: "..2... ..2899 ++28.A 655.7A 64447A 33..11" },
  { moves: 22, board: "..28BB 66289E 7.++9E 7.CC33 11144D .AA55D" },
  { moves: 25, board: ".21166 .255.A 32++.A 3887.A ...744 .BB99." },
  { moves: 23, board: "AA8... B.8777 B++53. 664539 .24119 .2...." },
  { moves: 22, board: ".A99CC .A33.7 B++..7 B22447 5..611 5886.." },
  { moves: 22, board: "..888. 664.A. ++42A. ..B2.1 .9B551 .93377" },
  { moves: 21, board: "772..6 9.2336 9++51D CC.51D ...4BB AAA488" },
  { moves: 22, board: "..3332 11BB72 .++47C 66947C D.9555 D88AA." },
  { moves: 21, board: ".B344. .B3AAD ++79.D 55796D C8826. C..211" },
  { moves: 22, board: "..7755 .3399B ..++1B .6441B .6.2AA .882.." },
  { moves: 21, board: "533377 5....9 .81++9 .81229 ..1644 AAA6.." },
  { moves: 21, board: "3.996. 3.2.6. ++2..4 11...4 777884 ....55" },
  { moves: 22, board: "446661 CC..B1 3++8B. 3.7855 997... AAA22." },
  // { moves: 22, board: "3334.A 8..45A 8.++57 1.2.57 1.2666 ...99." },
  // { moves: 22, board: "89966. 8.AA77 ++4..3 CC4.13 .55513 .22.BB" },
  // { moves: 24, board: "BBB..7 CC9997 A.++12 A.6612 ..5833 4458.." },
  // { moves: 24, board: "CC6D.. 9A6DBB 9A++.1 2884.1 2..477 255533" },
  // { moves: 27, board: "...588 ..952. ++932A 1..37A 16667. BB.444" },
  // { moves: 22, board: "2221AA .441BB ++71.9 5.7669 5.338. ....8." },
  // { moves: 21, board: "6..55. 6.7722 ++B..3 88B.43 .99943 .AA.11" },
  // { moves: 22, board: ".992.. .65233 .65++7 B444.7 B88..C .11AAC" },
  // { moves: 22, board: "225... ..533. ++86.9 BB8649 711649 7.AA.." },
  // { moves: 22, board: "..B99C 8.B25C 8++25. 836611 .3A444 77A..." },
  // { moves: 25, board: "69944. 6.888. ++5A.. ..5A33 .7771. ..221." },
  // { moves: 23, board: "C.3.AA C.3755 ++9748 229.48 .B1116 .B...6" },
  // { moves: 26, board: "3446.. 3.1699 ++1..8 ..1558 .777.8 .222.." },
  // { moves: 23, board: "..2223 BBAAA3 .++7.1 6997.1 6.5888 ..544." },
  // { moves: 21, board: "..68DD 446823 C.++23 C..777 11BBB5 AA99.5" },
  // { moves: 25, board: ".CC66. 55AA4B .++14B 89914. 8.233. 772..." },
  // { moves: 23, board: "..BBB. 2244C6 ++.8C6 555837 911137 9.AAA." },
  // { moves: 23, board: "111DDB 7CC.5B 7A++5. 4A.233 4892.6 489EE6" },
  // { moves: 24, board: ".488AA C4333B C++..B 11566B 9.52DD 9772.." },
  // { moves: 26, board: "55.6AA ...6.. ++.2.9 1772.9 1.3888 443BB." },
  // { moves: 23, board: "AA9995 8111B5 8.++B2 6643.2 ..43CC 774..." },
  // { moves: 21, board: ".3..44 .35562 ++.862 999867 A.1117 A.CCBB" },
  // { moves: 24, board: "8.3.44 8.3.79 8++.79 665559 11AAA. 22..BB" },
  // { moves: 23, board: "BBB55A 1113.A ++43.7 2.4.87 2.668. 99...." },
  // { moves: 24, board: "22.... 7888A. 7++3A. 5443A. 5.911. 669..." },
  // { moves: 24, board: "A.9.33 A.9.81 A++.81 BB5551 22777. ..6644" },
  // { moves: 23, board: "66882A 47112A 47++2D 47B33D 55B... CC99.." },
  // { moves: 24, board: "A..... A.447. ++2.73 112..3 666993 ..5588" },
  // { moves: 22, board: ".B.811 AB58.7 AB5++7 994.62 C.4.62 C.33.." },
  // { moves: 22, board: "AA.22. 6..455 6++49. 68339. .81BB. 771..." },
  // { moves: 22, board: ".54488 .577.2 .++..2 A33992 A..B66 .11B.." },
  // { moves: 21, board: "AAA75. 866752 8.++52 893311 .9B... ..B44." },
  { moves: 24, board: ".777A6 .955A6 .9++A8 DD24.8 1.24BB 133CC." },
  { moves: 28, board: "9994B. 5884B2 53++B2 73A..6 7.A116 7....." },
  { moves: 24, board: "88822A 66551A D.++1E D449.E ..39CC BB3777" },
  { moves: 21, board: "33DD2. 777C21 ++5C.1 ..588A 4.BB.A 49966." },
  { moves: 27, board: "BB.99. 8115.4 8++5.4 3325.. ..2AAA .66677" },
  { moves: 27, board: "43331. 4..21. ++5287 ..5A87 ...A66 ..99.." },
  // { moves: 25, board: ".66773 ..D883 ++D..9 1.A229 1.A4CC .BB455" },
  // { moves: 22, board: "BB555D 44117D .++276 ACC276 A.8999 ..833." },
  // { moves: 21, board: "BB22.9 ..7559 ++74.9 3CC4AA 3.1166 3..888" },
  // { moves: 23, board: "44771. 555C13 ++2C.3 A.2BB8 A.66.8 ..9998" },
  // { moves: 22, board: "..5222 115A64 3++A64 3998.. ...8.7 BBB8.7" },
  // { moves: 21, board: "C511AA C5.888 3++6.. 3..677 3.4229 BB4..9" },
  // { moves: 22, board: ".2889. 52.39. 5++37. .1147A ...4.A BB.66A" },
  // { moves: 24, board: ".BB3.. ..1344 .A1++8 .A.558 .7772C 99662C" },
  // { moves: 27, board: ".13399 6188.2 61++A2 6CC7A2 ...755 ..44BB" },
  { moves: 22, board: "2.AA.. 2.1... ++1.65 334465 999775 ....88" },
  { moves: 21, board: "1.4... 1.4.33 ++8..6 ..8996 AAA2.6 .55277" },
  { moves: 22, board: "..CC22 333668 ++.918 D7791A D.B55A 44B..." },
  { moves: 21, board: "A.2.66 A.2.73 A++.73 441113 ..8899 ....55" },
  { moves: 21, board: ".AA8.. .2.866 .2++17 44C.17 5.C..3 5BB993" },
];

/*
.  => empty space
+  => main
*/
export const PUZZLES = [
  {
    "moves": 3,
    "board": "...... .77.AA ++3.1. ..3.1. BB.... ....44"
  },
  {
    "moves": 5,
    "board": ".1.233 .1.244 ++.2.5 .67775 .6.... ......"
  },
  {
    "moves": 7,
    "board": ".34567 134567 1++567 1.8999 228... ......"
  },
  {
    "moves": 11,
    "board": "...... .755AA 87++19 833219 BB62.9 ..6244"
  },
  {
    "moves": 11,
    "board": "AA.BBB CCC.DE FG++DE FGHHH. II.... JJKKK."
  },
  // // // {
  // // //   "moves": 12,
  // // //   "board": "..21AA 332146 C8++46 C8999. 77.... BB555."
  // // // },
  // // // {
  // // //   "moves": 13,
  // // //   "board": "..88A9 .22.A9 16++A9 16.577 C335.. C44BB."
  // // // },
  // // // {
  // // //   "moves": 14,
  // // //   "board": ".22... 4.DAA7 4.D++7 11D337 8699BB 8655CC"
  // // // },
  // // // {
  // // //   "moves": 15,
  // // //   "board": ".....8 3..6.8 3++6.8 399744 .1.722 .155.."
  // // // },
  // // // // {
  // // // //   "moves": 16,
  // // // //   "board": ".55722 ...739 ..++39 668839 ..1AAA ..1.44"
  // // // // },
  // // // // {
  // // // //   "moves": 17,
  // // // //   "board": "5..888 5AAA24 ++..24 116CC4 9.63BB 9773.."
  // // // // },
  // // // // {
  // // // //   "moves": 18,
  // // // //   "board": ".9966. EE288D ++241D 33341D C.BB77 CAAA55"
  // // // // },
  // // // // {
  // // // //   "moves": 20,
  // // // //   "board": ".9.366 .9.341 8B++41 8B554. ..C777 AAC.22"
  // // // // },
  // // // // {
  // // // //   "moves": 21,
  // // // //   "board": "992254 ...854 1++8.4 1.A666 ..A.7. .3337."
  // // // // },
  // // // // {
  // // // //   "moves": 22,
  // // // //   "board": "777.A3 ....A3 ++84.3 9984.. 511666 5..222"
  // // // // },
  // // // // {
  // // // //   "moves": 19,
  // // // //   "board": "55886B .99.6B ++E16. D2E177 D2CCAA .4433."
  // // // // },
  // // // // {
  // // // //   "moves": 23,
  // // // //   "board": "...155 .A.122 .A++84 .66684 ..3774 ..399."
  // // // // },
  // // // // // {
  // // // // //   "moves": 25,
  // // // // //   "board": ".4475B ...75B .A++5. .A1C33 8.1C66 82299."
  // // // // // },
  // // // // // {
  // // // // //   "moves": 28,
  // // // // //   "board": "..BB8. 222785 .++715 43371C 4.6AAC ..6999"
  // // // // // },
  // // // // // {
  // // // // //   "moves": 27,
  // // // // //   "board": "BAA7.. B.C766 .1C++D .1.99D 333.84 552284"
  // // // // // },
  // // // // // {
  // // // // //   "moves": 26,
  // // // // //   "board": ".CC66. ..4779 ++4..9 5.4AA2 5BB1.2 .33188"
  // // // // // },
  // // // // // {
  // // // // //   "moves": 24,
  // // // // //   "board": "A.B.CC A.B677 ++461D 334.1D .85559 .822.9"
  // // // // // },
  // // // // // {
  // // // // //   "moves": 29,
  // // // // //   "board": ".C2299 .C4448 ++51.8 7.51BB 7.336A ....6A"
  // // // // // },
  // // // // // {
  // // // // //   "moves": 31,
  // // // // //   "board": "6661.. ..51AA ++5..8 77CC.8 2999BB 2.3344"
  // // // // // },
  // // // // // // {
  // // // // // //   "moves": 30,
  // // // // // //   "board": ".633CC .6D.BB ++D.17 8.9917 85521A 4442.A"
  // // // // // // },
  // // // // // // {
  // // // // // //   "moves": 32,
  // // // // // //   "board": "B177CC B1..5. B1++5A 89925A 8..233 66.44."
  // // // // // // }
];

import React, { useContext, useState } from "react";
import { lsLoad, lsStore } from '../utils'
const LS_CONTEXT_KEY = '__global_context__';
const persisedStorage = lsLoad(LS_CONTEXT_KEY)
// console.log(persisedStorage)
// INIT STATE
export const initialState = {
  show: "splash"
};

const Context = React.createContext(persisedStorage);

const ContextProvider = (props) => {
  const [state, regularSetState] = useState(initialState);

  const setState = (newState) => {
    // classs component like setState...
    regularSetState(prevState => ({
      ...prevState,
      ...newState
    }))
  }
  lsStore(LS_CONTEXT_KEY, state)
  const value = { state, setState };
  return <Context.Provider value={value}>{props.children}</Context.Provider>;
};

const withContextProvider = (component) => {
  return <ContextProvider>{component}</ContextProvider>;
};
const GlobalContextConsumer = Context.Consumer;

const useGlobalContext = () => {
  const context = useContext(Context);
  return context;
};

const GlobalConsumer = ({ children }) => {
  return (
    <GlobalContextConsumer>
      {context => {
        if (context === undefined) {
          throw new Error('CountConsumer must be used within a CountProvider')
        }
        return children(context)
      }}
    </GlobalContextConsumer>
  )
}

export {
  GlobalConsumer,
  Context as GlobalContext,
  useGlobalContext,
  ContextProvider,
  GlobalContextConsumer,
  withContextProvider,
};

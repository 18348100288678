export function lsLoad(key) {
  let value;
  try {
    value = sessionStorage.getItem(key);
  }
  catch (e) {
    return null;
  }
  if (value === "" || value === null)
    return null;
  return JSON.parse(value);
}

export function lsClear() {
  sessionStorage.clear();
}
export function lsStore(key, value) {
  try {
    sessionStorage.setItem(key, JSON.stringify(value));
  }
  catch (e) {
    // Just ignore errors
  }
}
export function loadRecords() {
  return lsLoad("puzzle-records") || {};
}
export function storeRecords(records) {
  lsStore("puzzle-records", records);
}
export function storeBerries(records) {
  lsStore("berriesCollection", records);
}
export class Piece {
  constructor(props) {
    this.isMain = props.isMain;
    this.x = props.x;
    this.y = props.y;
    this.dx = props.dx;
    this.dy = props.dy;
  }
}

import React from "react";
import ReactDOM from "react-dom";
import "./static/sass/common.scss";
import "./static/sass/index.scss";
import "./static/sass/reset.scss";
// import Base from './Base';
import { ContextProvider } from "./context";
import Home from "./home";
import { storage } from "./services/config/storage";

// reset the local storage on startup
storage.destroy.all();

ReactDOM.render(
  <React.StrictMode>
    <ContextProvider>
      <Home />
    </ContextProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
